import { colors } from 'palette';

import { FormLabel as _FormLabel, FormLabelProps as _FormLabelProps } from '@chakra-ui/react';

interface FormLabelProps extends _FormLabelProps {
  children: React.ReactNode;
}

export function FormLabel({ children, ...props }: FormLabelProps) {
  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <_FormLabel mb={'10px'} fontSize={'xl'} fontWeight={'normal'} color={colors.textPrimary} {...props}>
      {children}
    </_FormLabel>
  );
}
