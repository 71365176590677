import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import {
  mode,
} from '@chakra-ui/theme-tools'; // import utility to set light and dark mode props

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys);

// define a custom variant
const buttonVariant = definePartsStyle((props) => {
  return {
    tab: {
      border: 'none',
      color: mode('white', 'black')(props),
      // use colorScheme to change background color with dark and light mode options
      bg: mode('gray.500', 'white')(props),
      borderRadius: '3xl',
      borderBottom: 'none',
      _selected: {
        bg: mode('white', 'gray.800')(props),
        color: mode('black', 'white')(props),
        borderColor: 'inherit',
        borderBottom: 'none',
      },
    },
    tablist: {
      border: 'none',
    },
    tabpanel: {
      border: 'none',
      borderBottomRadius: 'lg',
      borderTopRightRadius: 'lg',
      padding: '32px 0px 0px 0px',
    },
  };
});

const variants = {
  button: buttonVariant,
};

// export the component theme
export const tabsTheme = defineMultiStyleConfig({ variants });
