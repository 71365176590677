import create from 'zustand';

interface AppConfig {
  dataFetchIntervalS: number;
  dataFetchIntervalMs: number;
  dataFetchDurationS: number;
  dataFetchDurationMs: number;
  sregCountFetchDurationS: number;
  sregCountFetchDurationMs: number;
  sregCountFetchIntervalS: number;
  sregCountFetchIntervalMs: number;
  isFullScreen: boolean;
}

const useAppConfigStore = create<AppConfig>((set) => ({
  dataFetchIntervalS: 5,
  dataFetchIntervalMs: 5000,
  dataFetchDurationS: 5,
  dataFetchDurationMs: 5000,
  sregCountFetchDurationS: 10,
  sregCountFetchDurationMs: 10000,
  sregCountFetchIntervalS: 1,
  sregCountFetchIntervalMs: 1000,
  isFullScreen: false,
}));

export default useAppConfigStore;
