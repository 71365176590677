import { colors } from 'palette';
import { breakpoints } from 'theme/breakpoints';
import { buttonTheme } from 'theme/components/Button';
import { checkboxTheme } from 'theme/components/CheckBox';
import { menuThemes } from 'theme/components/Menu';
import { popoverTheme } from 'theme/components/Popover';
import { switchTheme } from 'theme/components/Switch';
import { tabsTheme } from 'theme/components/Tab';

import {
  extendTheme,
  ThemeConfig,
} from '@chakra-ui/react';
import {
  GlobalStyleProps,
  mode,
} from '@chakra-ui/theme-tools';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,

  breakpoints: breakpoints,
  styles: {
    global: (props: GlobalStyleProps) => ({
      'html, body': {
        fontFamily: 'Inter, Arial, sans-serif',
      },
      ':root': {
        // text
        '--textPrimary': mode('#7C7E80', '#BEBEBE')(props),
        '--textSecondary': mode('#262736', '#FFFFFF')(props),

        '--textLight': mode('#fff', '#262736')(props),
        '--textWhite': mode('#F5F5F5', '#F5F5F5')(props),
        // background
        '--bgPrimary': mode('#f2edf5', '#1A1A2D')(props),
        '--bgContent': mode('#fff', '#262736')(props),
        '--bgGreen': mode('#59BD83', '#59BD83')(props),
        // nav header
        '--navHeaderIcon': mode('#7C7E80', '#C8C8EC')(props),
        // icon button
        '--borderPrimary': mode('#DCDCDC', '#3C3C5F')(props),
        // button
        '--largerButtonBg': mode('#fff', '#262736')(props),
        '--scheduleIcon': mode('#00C51C', 'yellow')(props),
        '--subButtonBg': mode('#7C7E80', '#C8C8EC')(props),
        // statuss
        '--normalStatus': mode('#00C81E', '#00C81E')(props),
        '--nornalStatusDarker': mode('#59BD83', '#59BD83')(props),
        '--warningStatus': mode('#FBB03B', '#FBB03B')(props),
        '--dangerStatus': mode('#FF5F8D', '#FF5F8D')(props),
        // app
        '--appBlue': mode('#5EA0EB', '#5EA0EB')(props),
        '--appGray': mode('#dcdcdc', '#3C3C5F')(props),
        // chart
        '--chartPurple': mode('#694CE2', '#694CE2')(props),
        '--chartLightPurple': mode('#CA97F8', '#CA97F8')(props),
        '--chartBlue': mode('#5EA0EB', '#5EA0EB')(props),
        '--chartPeach': mode('#FF5F8D', '#FF5F8D')(props),
        '--chartOrange': mode('#FBB03B', '#FBB03B')(props),
        '--chartRed': mode('#FF5050', '#FF5050')(props),
        '--chartLightGreen': mode('#5CFF78', '#5CFF78')(props),
        '--chartBlueGreen': mode('#00B4B4', '#00B4B4')(props),
        '--chartGreen': mode('#5CFF78', '#5CFF78')(props),
        //
      },
    }),
  },
  colors,
  components: {
    Button: buttonTheme,
    Tabs: tabsTheme,
    Menu: menuThemes,
    Popover: popoverTheme,
    Switch: switchTheme,
    Checkbox: checkboxTheme,
  },
  // colors: {
  //   navTest: mode('red', '#ff0000'),
  //   navDark: '#171923',
  //   navLight: '#efefef',
  //   mainDark: '#1B202B',
  //   mainLight: '#F6F6F6',
  //   cardDark: '#2D3748',
  //   cardLight: 'white',
  //   card: {
  //     dark: '#2D3748,#47546a 65%',
  //     light: 'white, #f2f1f1 65%',
  //     warning: '#FFA500,#d56d0f 50%',
  //     error: '#ff8772,#ff4e2f 50%',
  //   },
  //   navSelectedDark: '#2D3748',
  //   navSelectedLight: 'white',
  //   navColorDark: 'white',
  //   navColorLight: '#292929',
  //   title: {
  //     dark: '#9fc8ff',
  //     light: '#232323a3',
  //     normal: 'green.400',
  //     warning: 'white', //'#ED8936',
  //     danger: 'white', // 'tomato',
  //   },
  //   content: {
  //     dark: 'white',
  //     light: 'black',
  //   },
  //   table: {
  //     content: {
  //       dark: '#F7FAFC',
  //       light: '#718096',
  //     },
  //     header: {
  //       dark: 'orange',
  //       light: 'black',
  //     },
  //   },
  //   button: {
  //     main: {
  //       bg: {
  //         dark: '#F7FAFC',
  //         light: '#5779d4',
  //       },
  //     },
  //     safe: {
  //       bg: {
  //         dark: '#48BB78',
  //         light: '#48BB78',
  //       },
  //     },
  //     danger: {
  //       bg: {
  //         dark: 'tomato',
  //         light: 'tomato',
  //       },
  //     },
  //   },
  //   calendar: {
  //     light: {
  //       bg: '',
  //     },
  //     dark: {
  //       bg: 'white',
  //       color: 'black',
  //       hoverBg: 'black',
  //       hoverColor: 'white',
  //     },
  //   },
  //   error: {
  //     text: '#fc8181',
  //   },
  //   chartTooltip: {
  //     dark: {
  //       bg: 'rgba(45, 55, 72, 0.7)',
  //     },
  //     light: {
  //       bg: 'rgba(255, 255, 255, 0.7)',
  //     },
  //   },
  // },
});

export default theme;
