import { SvgIcon } from './type';

export default function PresentIcon(props: SvgIcon) {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Group">
        <path
          id="Vector"
          d="M30.7527 35H4.24727C1.91128 35 0 33.0502 0 30.6369V4.36309C0 1.9634 1.89801 0 4.24727 0H30.7527C33.0887 0 35 1.94976 35 4.36309V30.6369C35 33.0366 33.0887 35 30.7527 35ZM4.24727 1.75883C2.84037 1.75883 1.69886 2.93147 1.69886 4.37674V30.6506C1.69886 32.0958 2.84037 33.2685 4.24727 33.2685H30.7527C32.1596 33.2685 33.3011 32.0958 33.3011 30.6506V4.37674C33.3011 2.93147 32.1596 1.75883 30.7527 1.75883H4.24727Z"
          fill="white"
        />
        <path
          id="Vector_2"
          d="M12.7547 25.3331C12.6087 25.3331 12.4627 25.2922 12.33 25.2104C12.0646 25.0604 11.9053 24.7604 11.9053 24.4605V10.5532C11.9053 10.2396 12.0646 9.95325 12.33 9.80327C12.5955 9.65329 12.914 9.65329 13.1795 9.80327L24.9125 16.7569C25.178 16.9069 25.3372 17.2068 25.3372 17.5068C25.3372 17.8204 25.178 18.1068 24.9125 18.2567L13.1795 25.2104C13.0468 25.2922 12.9007 25.3331 12.7547 25.3331ZM13.6042 12.0529V22.947L22.7889 17.5068L13.6042 12.0529Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
