import { ILoginReq, ILoginRsps } from './types';

export async function mutateLogin(input: ILoginReq): Promise<ILoginRsps> {
  const mutate = JSON.stringify({
    query: `
    mutation{
      login(email:"${input.email}", password: "${input.password}"){
          _id
          username
          email
          role
      }
  }
    `,
  });
  const response = await fetch((process.env.REACT_APP_API_URL ?? '') + '/graphql/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: mutate,
  });

  return await response.json();
}
