const en = {
  translation: {
    field: {
      title: 'Field',
    },
    nav: {
      dashboard: 'Grid System Information',
      storage: 'Energy Storage System Interface',
      schedule: 'Grid Scheduling Interface',
      operate: 'Administrator Operation Interface',
      history: 'Historical Abnormal Data Query',
      present: 'Presentation Interface',
      logout: 'Logout',
    },
    header: {
      dashboard: {
        title: 'Grid System',
        sysOpStat: 'ESS Operation Status',
        hz: 'Taiwan Power Frequency',
        load: 'Load Power',
        opMode: 'Execution Function',
        contractCap: 'Contract Capacity',
        ctrlStat: 'Controller Status',
        bmsWarnWDegree: 'BMS System Warning (w/ deg)',
        bmsWarnWODegree: 'BMS System Warning (w/o deg)',
        bmsHeartBeat: 'BMS Heartbeat',
        controllerWarning: 'Controller Warning',
        controllerBaseHz: 'Base Frequency',
        noBid: 'No Bidding Value',
        manualBid: 'Obligatory Capacity for Large Electricity Consumers',
        sRegManualBid: 'sReg Bidding Value',
        dRegManualBid: 'dReg Bidding Value',
        sRegBasic: 'sReg Baseline Value',
        meter: {
          normal: 'Normal',
          unusual: 'Unusual',
        },
      },
      storage: {
        title: 'Energy Storage System',
      },
      schedule: {
        title: 'Grid Scheduling Interface',
      },
      operate: {
        title: 'Administrator Operation Interface',
        controller: 'Controller Status',
        pcs: 'PCS Status',
        bms: 'BMS Status',
      },
      history: {
        title: 'Historical Data Query',
      },
      common: {
        lastUpdate: 'Last Update Time',
        sregGTZero: 'sReg Event Triggered',
        sregEQ: 'sReg Normal',
      },
      login: {
        title: 'Login',
        email: 'Email',
        emailHint: 'Please enter your Email',
        password: 'Password',
        passwordHint: 'Please enter your password',
        login: 'Confirm',
        cancel: 'Cancel',
        errorMsg: {
          required: 'This field is required',
          email: 'Please enter a valid Email format',
          password: 'Please enter a valid password format',
          needFix: 'Please enter correct information',
          failed: 'Incorrect Email or password',
        },
      },
    },
    userMenu: {
      profile: 'Profile',
      logout: {
        title: 'Logout',
        errorMsg: {
          failed: 'Logout failed',
        },
      },
      members: 'Member Management',
    },

    page: {
      dashboard: {
        chart: {
          title: 'Data Date',
          realTimeStat: 'Real-time Operation Information',
          loadBalance: 'Real-time Grid Operation Curve',
          actionHint: 'Data Date/Range',
          socCondition: 'Real-time SoC Status of Energy Storage',
          electricityPrice: 'High Voltage Time-based Electricity Price',
          frequency: 'Grid System Frequency',
          energyStoragePower: 'Energy Storage Output Power',
          sregContinue: 'sReg Duration',
          time: 'Time',
          trigger: 'sReg Trigger Frequency',
          stopper: 'sReg End Frequency',
          base: 'Base Frequency',
          fifteenMin: 'Last 15 Minutes',
          halfHour: 'Last 30 Minutes',
          oneHour: 'Last 1 Hour',
          threeHour: 'Last 3 Hours',
          sixHour: 'Last 6 Hours',
          nineHour: 'Last 9 Hours',
          twelveHour: 'Last 12 Hours',
        },
        batteryInfo: {
          title: 'Battery Management System Information (BMS)',
          status: 'Battery Status',
          rackStatus: 'Battery Rack Status',
          soc: 'Total Battery Capacity (SoC)',
          socCellsSoc: 'Battery Rack Capacity (SoC) View',
          soh: 'Battery SOH',
          powering: 'Charging/Discharging Status',
          voltage: {
            title: 'Cell Voltage',
            subTitle: '(Protection Voltage 3800mV)',
          },
          current: {
            title: 'Cell Current',
            subTitle: '(Overcharge Current Protection 1680 A)',
          },
          temperature: 'Battery Temperature',
          coreTemperature: {
            title: 'Cell Temperature',
            subTitle: '(Protection Temperature 60 ℃)',
          },
          rack: 'Battery Rack',
          sysWarn: 'System Warning',
          rackSoc: 'Rack SoC',
          warningSys: 'Warning System',
          sysStatus: 'System Status',
          wDeg: 'With Degree',
          woDeg: 'Without Degree',
        },
        batteryWarnInfo: {
          title: 'BMS System Warning Information',
        },
        pcsInfo: {
          title: 'Power Conversion System Information (PCS)',
          pcsStat: 'PCS Status',
          pcsWarn: 'PCS Warning',
          pcsError: 'PCS Error',
          pcsHeartBeat: 'PCS Heartbeat',
          pcsOutput: 'Energy Storage Charging/Discharging Power',
          pcsGridActivePower: 'Grid Active Power',
          pcsGridReactivePower: 'Grid Reactive Power',
          pcsGridRSVoltage: 'Grid RS Voltage',
          pcsGridSTVoltage: 'Grid ST Voltage',
          pcsGridTRVoltage: 'Grid TR Voltage',
          pcsGridRS: 'RS',
          pcsGridST: 'ST',
          pcsGridTR: 'TR',
          pcsTotalCurrentR: 'Total Current R',
          pcsTotalCurrentS: 'Total Current S',
          pcsTotalCurrentT: 'Total Current T',
          pcsDcVoltage: 'DC Voltage Bus',
        },
      },
      schedule: {
        title: 'System is Running',
        switch: 'Auto / Manual Schedule',
        autoDate: 'Auto Schedule Date',
        mode: {
          prevDesc: 'Current Operation Mode',
          autoTitle: 'Auto',
          manualTitle: 'Manual',
          modeUnmatchWarning: 'Current operation mode does not match the settings page',
        },
        saveHint: 'Remember to save and apply your changes',
        table: {
          header: {
            time: 'Time',
            standby: 'Standby',
            allStandby: 'All Standby',
            smart: 'Peak Shaving and Valley Filling',
            sreg: 'sReg',
            dreg: 'dReg',
            allSreg: 'All sReg',
            arbitrage: 'Arbitrage',
            manually: 'Large Power User',
            charging: 'Charging',
            powering: 'Discharging',
          },
        },
        strategies: {
          standBy: 'Standby',
          smart: 'Peak Shaving and Valley Filling (Arbitrage)',
          sReg: 'sReg',
          manually: 'Large Power User Participating in Capacity',
          dReg: 'dReg',
        },
        modal: {
          title: 'Applying Strategy',
          confirm: 'Confirm',
          cancel: 'Cancel',
          smartConfig: {
            title: 'Peak Shaving and Valley Filling Strategy Parameters',
            errorMsg: {
              isRequired: 'This field is required',
              shouldLTEMaxSoc: 'Cannot be greater than the maximum SoC',
              shouldGTEZero: 'Cannot be less than 0',
              shouldLTEkWT1: 'Cannot be greater than kW_T1',
            },
          },
          saveAndApply: {
            title: 'Save and Apply Strategy',
            content: 'Are you sure you want to save and apply the current strategy?',
          },
        },
        hint: {
          cannotEdit: 'Cannot modify during sReg mode',
          sregContractCapacity: 'sReg Contract Capacity',
          dregContractCapacity: 'dReg Contract Capacity',
          batteryTarget: 'Large Power User Participating Capacity',
        },
        button: {
          save: 'Save',
          apply: 'Save and Apply Strategy',
          confirm: 'Confirm',
          cancel: 'Cancel',
          allStandby: 'All Day Standby',
          allManually: 'All Day Participating',
          allSDreg: 'All Day sReg',
          allSmart: 'All Day Peak Shaving and Valley Filling',
        },
        errorMsg: {
          isRequired: 'This field is required',
          min: 'Cannot be less than 0',
          minHint: 'Cannot be less than',
          max100: 'Cannot be greater than 100',
          max65535: 'Cannot be greater than 65535',
          maxHint: 'Cannot be greater than',
          invalidPrecision1: 'Only supports 1 decimal place',
          integer: 'Only supports integers',
        },
        batchSchedule: {
          title: 'Batch Operation',
        },
        advanced: {
          title: 'Advanced Settings',
          battery: {
            errorMsg: {
              shouldGTEZero: 'Cannot be less than 0',
              shouldLTEHundred: 'Cannot be greater than 100',
              shouldLTE65535: 'Cannot be greater than 65535',
              shouldGTEHint: 'Cannot be less than',
              shouldLTEHint: 'Cannot be greater than',
            },
          },
        },
        toast: {
          saveAndApply: {
            saving: 'Saving and Applying',
            success: {
              title: 'Save and Apply Completed',
            },
            error: {
              title: 'Save and Apply Failed',
            },
          },
        },
        tab: {
          auto: {
            title: 'Auto',
          },
          manual: {
            title: 'Manual',
          },
        },
      },
      operate: {
        opTitle: 'Dispatch Parameters',
        socTitle: 'SoC Parameters',
        powerSwitchTitle: 'Enable/Disable Energy Storage System',
        bmsSwitchTitle: 'BMS Switch',
        pcsSwitchTitle: 'PCS Switch',
        button: {
          startSaving: 'Start',
          starting: 'Energy Storage System Starting',
          stopSaving: 'Stop',
          stopping: 'Energy Storage System Stopping',
          emergencyStop: 'Emergency Stop',
          emergencyStopping: 'Emergency Stopping',
          set: 'Set',
          setting: 'Setting',
        },
        toast: {
          warning: {
            title: 'Error occurred during execution',
          },
          error: {
            title: 'Unexpected error occurred during execution',
          },
          stoppingStorage: {
            title: 'Energy Storage System Stopping',
            desc: 'This operation may take 10 seconds',
            success: {
              title: 'Stop Completed',
            },
            error: {
              title: 'Stop Failed',
            },
          },
          startingStorage: {
            title: 'Energy Storage System Starting',
            desc: 'This operation may take 1 minute',
            success: {
              title: 'Start Completed',
            },
            error: {
              title: 'Start Failed',
            },
          },
          stoppingBms: {
            title: 'BMS Disconnecting',
            desc: 'This operation may take 1 minute',
            success: {
              title: 'Disconnect Completed',
            },
            error: {
              title: 'Disconnect Failed',
            },
          },
          startingBms: {
            title: 'BMS Connecting',
            desc: 'This operation may take 1 minute',
            success: {
              title: 'BMS Connect Completed',
            },
            error: {
              title: 'BMS Connect Failed',
            },
          },
          stoppingPcs: {
            title: 'PCS Stopping',
            desc: 'This operation may take 1 minute',
            success: {
              title: 'PCS Disconnect Completed',
            },
            error: {
              title: 'PCS Disconnect Failed',
            },
          },
          startingPcs: {
            title: 'PCS Starting',
            desc: 'This operation may take 1 minute',
            success: {
              title: 'PCS Start Completed',
            },
            error: {
              title: 'PCS Start Failed',
            },
          },
          cleanningBmsNotify: {
            title: 'BMS Warning Clearing',
            success: {
              title: 'BMS Warning Clear Completed',
            },
            error: {
              title: 'BMS Warning Clear Failed',
            },
          },
          cleanningPcsNotify: {
            title: 'PCS Warning Clearing',
            success: {
              title: 'PCS Warning Clear Completed',
            },
            error: {
              title: 'PCS Warning Clear Failed',
            },
          },
          thresholdConfig: {
            title: 'Applying',
            success: {
              title: 'Parameter Apply Completed',
            },
            error: {
              title: 'Parameter Apply Failed',
            },
          },
          socConfig: {
            title: 'Applying',
            success: {
              title: 'Parameter Apply Completed',
            },
            error: {
              title: 'Parameter Apply Failed',
            },
          },
          setP: {
            title: 'Applying',
            success: {
              title: 'Parameter Apply Completed',
            },
            error: {
              title: 'Parameter Apply Failed',
            },
          },
        },
        modal: {
          powerStorage: {
            title: 'Energy Storage System',
            startConfirm: 'Are you sure you want to start the energy storage system?',
            stopConfirm: 'Are you sure you want to stop the energy storage system?',
          },
          bms: {
            title: 'BMS',
            startConfirm: 'Are you sure you want to perform BMS connect?',
            stopConfirm: 'Are you sure you want to perform BMS disconnect?',
          },
          pcs: {
            title: 'PCS',
            startConfirm: 'Are you sure you want to start PCS?',
            stopConfirm: 'Are you sure you want to stop PCS?',
          },
          confirm: 'Confirm',
          cancel: 'Cancel',
        },
        powerStorageSetting: {
          kwT1: 'KW_T1',
          kwT2: 'KW_T2',
          socT1: 'SOC_T1',
          socT2: 'SOC_T2',
          modal: {
            title: 'Please enter dispatch algorithm parameters',
            errorMsg: {
              required: 'This field is required',
              shouldGTEZero: 'Cannot be less than 0',
              shouldLTEHundred: 'Cannot be greater than 100',
              shouldLTE65535: 'Cannot be greater than 65535',
              integer: 'Only supports integers',
              invalidPrecision1: 'Only supports 1 decimal place',
            },
          },
        },
        socSetting: {
          maxSoC: 'Max SoC',
          minSoc: 'Min SoC',
          maintainSoc: 'Maintain SoC',
          contractCapacity: 'Contract Capacity',
          modal: {
            title: 'Please enter SoC settings',
            errorMsg: {
              required: 'This field is required',
              shouldGTEMinSoc: 'Should be greater than Min SoC',
              shouldLTEMaxSoc: 'Cannot be greater than Max SoC',
              shouldGTEZero: 'Cannot be less than 0',
              shouldLTEHundred: 'Cannot be greater than 100',
              shouldLTE65535: 'Cannot be greater than 65535',
              integer: 'Only supports integers',
              invalidPrecision1: 'Only supports 1 decimal place',
            },
          },
        },
        advancedOp: {
          title: 'Advanced',
          op: {
            bmsConnect: 'BMS Connect',
            bmsConnecting: 'BMS Connecting',
            bmsDisconnect: 'BMS Disconnect',
            bmsDisconnecting: 'BMS Disconnecting',
            bmsNotifyClear: 'Clear BMS Warning',
            bmsNotifyClearing: 'Clearing',
            pcsStart: 'PCS Start',
            pcsStarting: 'PCS Starting',
            pcsStop: 'PCS Stop',
            pcsStopping: 'PCS Stopping',
            pcsNotifyClear: 'Clear PCS Warning',
            pcsNotifyClearing: 'Clearing',
            setPValue: {
              title: 'Manual P Value Control',
              header: 'Please enter P value',
              errorMsg: {
                required: 'This field is required',
                shouldGTENegativeHundred: 'Cannot be less than -100',
                shouldLTEHundred: 'Cannot be greater than 100',
                invalidPrecision: 'Only supports 2 decimal places',
              },
            },
          },
        },
      },
      history: {
        historyTitle: 'Historical Data Query',
        errorTitle: 'System Alarm Query',
        dateRange: 'Date Range',
        dataType: 'Data Type',
        dataAvgTime: 'Average Sampling Period',
        total: 'Total',
        day: 'Days',
        control: {
          query: 'Query',
          quering: 'Querying',
          export: 'Export',
          exporting: 'Exporting',
          select: 'Select Data',
          processing: 'Processing',
          advancedOp: 'Advanced',
        },
        notQueryYet: 'Not yet queried',
        noDataHint: 'No data available',
        table: {
          date: 'Date',
          time: 'Time',
          clearTime: 'Clear',
          message: 'Message',
          title: 'Cause',
          detail: 'Details',
        },
      },
      profile: {
        title: 'Personal Information',
        items: {
          name: 'Name',
          email: 'Email',
          role: 'Role',
          newPassword: 'New Password',
          newPasswordConfirm: 'Confirm New Password',
        },
        action: {
          edit: 'Edit',
          save: 'Save',
          cancel: 'Cancel',
        },
        inputHint: {
          name: 'Please enter your name',
          email: 'Please enter your email',
          newPassword: 'Please enter a new password',
          newPasswordConfirm: 'Please enter the new password again',
        },
        errorMsg: {
          required: 'This field is required',
          email: 'Please enter a valid email format',
          pwdShouldGTE10: 'Must be greater than 10 characters',
          pwdConfirm: 'Passwords do not match',
          updateFailed: 'Update failed',
        },
      },
      members: {
        title: 'Member Management',
        table: {
          columns: {
            name: 'Name',
            email: 'Email',
            role: 'Role',
            active: 'Active',
            deleted: 'Deleted',
            reset: 'Reset Password',
            delete: 'Delete',
            action: 'Action',
          },
          msg: {
            resetSuccess: 'Password reset successful',
            resetFailed: 'Password reset failed',
            deleteSuccess: 'Deletion successful',
            deleteFailed: 'Deletion failed',
          },
        },
        action: {
          resetPwd: {
            title: 'Reset User Password',
            ask: 'Are you sure you want to reset the password?',
            hint1: 'The password for user',
            hint2: 'will be reset',
          },
          delete: {
            title: 'Delete User',
            ask: 'Are you sure you want to delete?',
            hint1: 'User',
            hint2: 'will be deleted',
          },
          create: {
            title: 'Add User',
            titleShort: 'Add',
            username: 'Name',
            nameHint: 'Please enter name',
            email: 'Email',
            emailHint: 'Please enter email',
            errorMsg: {
              required: 'This field is required',
              email: 'Please enter a valid email format',
              failed: 'Failed to perform user creation',
            },
            successMsg: 'User creation successful',
          },
          confirm: 'Confirm',
          cancel: 'Cancel',
        },
      },
      present: {
        fieldTitle: 'Field Power Information',
        contractTitle: 'Contract Details',
        PowerTitle: 'ESS Charging and Discharging Power',
        OperateTitle: 'Current Operation Mode of the ESS',
      },
    },
    button: {
      apply: 'Apply',
      confirm: 'Confirm',
      cancel: 'Cancel',
    },
    bms: {
      status: {
        0: {
          title: 'Initializing',
          desc: 'Initializing',
        },
        1: {
          title: 'Normal',
          desc: 'Normal',
        },
        2: {
          title: 'Full Charge',
          desc: 'Full Charge',
        },
        3: {
          title: 'Full Discharge',
          desc: 'Full Discharge',
        },
        4: {
          title: 'System Warning',
          desc: 'System Warning',
        },
        5: {
          title: 'System Error',
          desc: 'System Error',
        },
      },
      rackStatus: {
        0: {
          title: 'Initializing',
          desc: '',
        },
        1: {
          title: 'Normal',
          desc: '',
        },
        2: {
          title: 'Full Charge',
          desc: '',
        },
        3: {
          title: 'Full Discharge',
          desc: '',
        },
        4: {
          title: 'Rack System Warning',
          desc: '',
        },
        5: {
          title: 'Rack System Error',
          desc: '',
        },
      },
      powerStatus: {
        status: {
          0: {
            title: 'All Battery Cabinets Power Off',
            desc: '',
          },
          1: {
            title: 'All Battery Cabinets Power On',
            desc: '',
          },
          2: {
            title: 'Power On Failure in Any Battery Cabinet',
            desc: '',
          },
          3: {
            title: 'Power Off Failure in Any Battery Cabinet',
            desc: '',
          },
        },
      },
      cell: {
        1: {
          name: 'Battery Cabinet 1',
        },
        2: {
          name: 'Battery Cabinet 2',
        },
        3: {
          name: 'Battery Cabinet 3',
        },
        4: {
          name: 'Battery Cabinet 4',
        },
        warningWithDegreeTitle: 'With Degree',
        warningWithoutDegreeTitle: 'Without Degree',
      },

      warningWithDegree: {
        0: {
          title: 'Normal',
          desc: 'Normal',
        },
        1: {
          title: 'Single cell over voltage warning',
          desc: 'warning 1',
        },
        2: {
          title: 'Single cell over voltage warning',
          desc: 'warning 2',
        },
        3: {
          title: 'Single cell over voltage warning',
          desc: 'warning 3',
        },
        4: {
          title: 'Single cell under voltage warning',
          desc: 'warning 1',
        },
        8: {
          title: 'Single cell under voltage warning',
          desc: 'warning 2',
        },
        12: {
          title: 'Single cell under voltage warning',
          desc: 'warning 3',
        },
        16: {
          title: 'Single cell over temperature warning',
          desc: 'warning 1',
        },
        32: {
          title: 'Single cell over temperature warning',
          desc: 'warning 2',
        },
        48: {
          title: 'Single cell over temperature warning',
          desc: 'warning 3',
        },
        64: {
          title: 'Single cell under temperature warning',
          desc: 'warning 1',
        },
        128: {
          title: 'Single cell under temperature warning',
          desc: 'warning 2',
        },
        192: {
          title: 'Single cell under temperature warning',
          desc: 'warning 3',
        },
        256: {
          title: 'Low SOC warning',
          desc: 'warning 1',
        },
        512: {
          title: 'Low SOC warning',
          desc: 'warning 2',
        },
        768: {
          title: 'Low SOC warning',
          desc: 'warning 3',
        },
        1024: {
          title: 'IMM Insulation Too Low',
          desc: 'IMM warning 1 (Level 1)',
        },
        2048: {
          title: 'IMM Insulation Too Low',
          desc: 'IMM warning 2 (Level 5)',
        },
        3072: {
          title: 'Default',
          desc: '',
        },
      },
      warningWithoutDegree: {
        0: {
          title: 'Normal',
          desc: '',
        },
        1: {
          title: 'Current over limit warning',
          desc: 'Current over limit warning',
        },
        2: {
          title: 'Warning for big voltage difference of single cell',
          desc: 'Warning for big voltage difference of single cell',
        },
        4: {
          title: 'Warning for big temperature difference within bank',
          desc: 'Warning for big temperature difference within bank',
        },
        8: {
          title: 'System overvoltage warning',
          desc: 'System overvoltage warning',
        },
        16: {
          title: 'System under voltage warning',
          desc: 'System under voltage warning',
        },
        32: {
          title: 'MCAN and SBMU warning (Level 3 Warning)',
          desc: 'Inner communication fault warning (MCAN and SBMU warning status)',
        },
        64: {
          title: 'Cell extreme temperature warning',
          desc: 'Cell extreme temperature warning',
        },
        128: {
          title: 'Cell extreme voltage warning',
          desc: 'Cell extreme voltage warning',
        },
        256: {
          title: 'ACAN and PCS fault (Level 3Fault)',
          desc: 'EMS heartbeat fault (ACAN and PCS warning)',
        },
      },
      rackWarningWithDegree: {
        0: {
          title: 'Normal',
          desc: 'Normal',
        },
        1: {
          title: 'Single cell high voltage warning (Level 1)',
          desc: 'warning 1',
        },
        2: {
          title: 'Single cell high voltage warning (Level 2)',
          desc: 'warning 2',
        },
        3: {
          title: 'Single cell high voltage warning (Level 3)',
          desc: 'warning 3',
        },
        4: {
          title: 'Single cell low voltage warning (Level 1)',
          desc: 'warning 1',
        },
        8: {
          title: 'Single cell low voltage warning (Level 2)',
          desc: 'warning 2',
        },
        12: {
          title: 'Single cell low voltage warning (Level 3)',
          desc: 'warning 3',
        },
        16: {
          title: 'High temperature warning (Level 1)',
          desc: 'warning 1',
        },
        32: {
          title: 'High temperature warning (Level 2)',
          desc: 'warning 2',
        },
        48: {
          title: 'High temperature warning (Level 3)',
          desc: 'warning 3',
        },
        64: {
          title: 'Low temperature warning (Level 1)',
          desc: 'warning 1',
        },
        128: {
          title: 'Low temperature warning (Level 2)',
          desc: 'warning 2',
        },
        192: {
          title: 'Low temperature warning (Level 3)',
          desc: 'warning 3',
        },
        256: {
          title: 'Low SOC warning (<10%)',
          desc: 'warning 1',
        },
        512: {
          title: 'Low SOC warning (<5%)',
          desc: 'warning 2',
        },
        768: {
          title: 'Low SOC warning',
          desc: 'warning 3',
        },
        1024: {
          title: 'TMS fault level 1',
          desc: 'TMS fault level 1',
        },
        2048: {
          title: 'TMS fault level 2',
          desc: 'TMS fault level 2',
        },
        3072: {
          title: 'TMS fault level 3',
          desc: 'TMS fault level 3',
        },
      },
      rackWarningWithoutDegree: {
        0: {
          0: {
            title: 'Normal',
            desc: 'Normal',
          },
          1: {
            title: 'Excessive charging current (Level 3 Warning)',
            desc: 'Excessive charging current (Level 3 Warning)',
          },
          2: {
            title: 'Excessive discharging current (Level 3 Warning)',
            desc: 'Excessive discharging current (Level 3 Warning)',
          },
          4: {
            title: 'Excessive cell voltage difference (Level 1 Warning)',
            desc: 'Excessive cell voltage difference (Level 1 Warning)',
          },
          8: {
            title: 'Temperature difference warning (within the cabinet) (Level 1 Warning)',
            desc: 'Temperature difference warning (within the cabinet) (Level 1 Warning)',
          },
          16: {
            title: 'High temperature (Level 4 Fault)',
            desc: 'High temperature (Level 4 Fault)',
          },
          32: {
            title: 'High/low cell voltage (Level 4 Fault)',
            desc: 'High/low cell voltage (Level 4 Fault)',
          },
          64: {
            title: 'Vbat system high voltage (internal), system low voltage (internal) (Level 3 Warning)',
            desc: 'Vbat system high voltage (internal), system low voltage (internal) (Level 3 Warning)',
          },
          128: {
            title: 'Main power relay sticking (Level 5 Fault)',
            desc: 'Main power relay sticking (Level 5 Fault)',
          },
          256: {
            title: 'Main power relay sticking (Level 5 Fault)',
            desc: 'Main power relay sticking (Level 5 Fault)',
          },
          512: {
            title: 'Main power relay open circuit (Level 4 Fault)',
            desc: 'Main power relay open circuit (Level 4 Fault)',
          },
          1024: {
            title: 'Main power relay open circuit (Level 4 Fault)',
            desc: 'Main power relay open circuit (Level 4 Fault)',
          },
          2048: {
            title: 'SBMU power supply abnormal (Level 1->4 Fault)',
            desc: 'SBMU power supply abnormal (Level 1->4 Fault)',
          },
          4096: {
            title: 'Abnormal balancing (Level 1 Warning)',
            desc: 'Abnormal balancing (Level 1 Warning)',
          },
          8192: {
            title: 'MCU self-check fault (Fault)',
            desc: 'MCU self-check fault (Fault)',
          },
          16384: {
            title: '(Reserved)',
            desc: '(Reserved)',
          },
          32768: {
            title: 'CCAN communication abnormal (Level 4 Fault)',
            desc: 'CCAN communication abnormal (Level 4 Fault)',
          },
        },
        1: {
          0: {
            title: 'Normal',
            desc: 'Normal',
          },
          1: {
            title: 'SCAN communication abnormal (Level 4 Fault)',
            desc: 'SCAN communication abnormal (Level 4 Fault)',
          },
          2: {
            title: '(Reserved)',
            desc: '(Reserved)',
          },
          4: {
            title: 'Invalid current sampling (Level 4 Fault)',
            desc: 'Invalid current sampling (Level 4 Fault)',
          },
          8: {
            title: 'Invalid cell voltage sampling (Level 4 Fault)',
            desc: 'Invalid cell voltage sampling (Level 4 Fault)',
          },
          16: {
            title: 'Invalid temperature sampling (Level 4 Fault)',
            desc: 'Invalid temperature sampling (Level 4 Fault)',
          },
          32: {
            title: 'High voltage bypass open circuit (Level 4 Fault)',
            desc: 'High voltage bypass open circuit (Level 4 Fault)',
          },
          64: {
            title: 'Tank MSD manual switch abnormal (Level 4 Fault)',
            desc: 'Tank MSD manual switch abnormal (Level 4 Fault)',
          },
          128: {
            title: 'Cabinet isolation opening abnormal (Level 4 Fault)',
            desc: 'Cabinet isolation opening abnormal (Level 4 Fault)',
          },
          256: {
            title: 'Cabinet fuse abnormal (Level 4 Fault)',
            desc: 'Cabinet fuse abnormal (Level 4 Fault)',
          },
          512: {
            title: 'Precharge timeout (Level 4->5 Fault)',
            desc: 'Precharge timeout (Level 4->5 Fault)',
          },
          1024: {
            title: 'Excessive charging/discharging current (Level 4 Fault)',
            desc: 'Excessive charging/discharging current (Level 4 Fault)',
          },
          2048: {
            title: 'Relay coil abnormal (Level 4 Warning)',
            desc: 'Relay coil abnormal (Level 4 Warning)',
          },
          4096: {
            title:
              'Comparison of 0x437 and 0x420, internal and external voltage comparison (Level 1 Warning)',
            desc: 'Comparison of 0x437 and 0x420, internal and external voltage comparison (Level 1 Warning)',
          },
          8192: {
            title: 'MCAN communication abnormal with MBMU (Level 3 Warning)',
            desc: 'MCAN communication abnormal with MBMU (Level 3 Warning)',
          },
          16384: {
            title: 'CSC=Tank, internal board power supply abnormal (Level 4 Fault)',
            desc: 'CSC=Tank, internal board power supply abnormal (Level 4 Fault)',
          },
          32768: {
            title: 'Current sensing power supply abnormal (Level 4 Fault)',
            desc: 'Current sensing power supply abnormal (Level 4 Fault)',
          },
        },
        2: {
          0: {
            title: 'Normal',
            desc: 'Normal',
          },
          1: {
            title: 'TMS communication abnormal (Level 1 Fault)',
            desc: 'TMS communication abnormal (Level 1 Fault)',
          },
          2: {
            title:
              'Water cooling machine status different from BMS expected operation status (Level 4 Fault)',
            desc: 'Water cooling machine status different from BMS expected operation status (Level 4 Fault)',
          },
          4: {
            title: 'Fire level 1 (Level 4 Fault)',
            desc: 'Fire level 1 (Level 4 Fault)',
          },
          8: {
            title: 'Fire level 2 × Fire controller delays 30 seconds to start aerosol (Level 5 Fault)',
            desc: 'Fire level 2 × Fire controller delays 30 seconds to start aerosol (Level 5 Fault)',
          },
          16: {
            title: 'High temperature (Triggered)',
            desc: 'High temperature (Triggered)',
          },
          32: {
            title: 'Smoke detection (Triggered)',
            desc: 'Smoke detection (Triggered)',
          },
          64: {
            title: 'Aerosol open state (Happened Level 4)',
            desc: 'Aerosol open state (Happened Level 4)',
          },
          128: {
            title: 'Abnormal aerosol opening (Abnormal opened Level 4)',
            desc: 'Abnormal aerosol opening (Abnormal opened Level 4)',
          },
          256: {
            title: 'Unable to open aerosol (Abnormal closed Level 4)',
            desc: 'Unable to open aerosol (Abnormal closed Level 4)',
          },
          512: {
            title: 'Keyhole hardware switch status of battery cabinet (Happened Level 4)',
            desc: 'Keyhole hardware switch status of battery cabinet (Happened Level 4)',
          },
          1024: {
            title:
              'Control box internal temperature over temperature (Over temperature fault in control box Level 4)',
            desc: 'Control box internal temperature over temperature (Over temperature fault in control box Level 4)',
          },
          2048: {
            title: 'TMS failure causing battery cabinet error (Level 4 Fault)',
            desc: 'TMS failure causing battery cabinet error (Level 4 Fault)',
          },
          4096: {
            title: '(Reserved)',
            desc: '(Reserved)',
          },
          8192: {
            title: '(Reserved)',
            desc: '(Reserved)',
          },
          16384: {
            title: '(Reserved)',
            desc: '(Reserved)',
          },
          32768: {
            title: '(Reserved)',
            desc: '(Reserved)',
          },
        },
      },
    },
    pcs: {
      status: {
        0: {
          title: 'PUP',
          desc: 'The start command has been received and the inverter takes 5 seconds to stabilize the system (communications, measurements, configuration, etc.).',
        },
        1: {
          title: 'INIT',
          desc: 'Basic conditions are being checked.',
        },
        2: {
          title: 'OFF',
          desc: `The battery inverter is stopped waiting for the start command which can be activated with the START button of the display unit when the battery inverter is in local mode or with the ON/OFF switch when the local mode is disabled.
          Characteristics: Battery Inverter - off. AC breakers - open. DC switch disconnector - open. Battery contactors - open. DC Link - discharged.`,
        },
        3: {
          title: 'PCHG',
          desc: `The battery inverter is doing the soft charge.
          Characteristics: Battery Inverter - off. AC breakers - open. DC switch disconnector - open. Battery contactors - closed. Soft charge contactors - closed.`,
        },
        4: {
          title: 'REA',
          desc: `The soft charge has been completed and the inverter is waiting for the command to start.
          Characteristics: Battery Inverter - on. AC breakers - open. DC switch disconnector - closed. Battery contactors - closed.`,
        },
        5: {
          title: 'WAIT',
          desc: `The start command has been received, starting conditions are fulfilled and the inverter is waiting for the delay time (during which starting conditions must be maintained) to elapse.`,
        },
        6: {
          title: 'ON',
          desc: `The battery inverter is operating normally.
          Characteristics: Battery Inverter - on. AC breakers - closed. DC switch disconnector - closed. Battery contactors - closed.`,
        },
        7: {
          title: 'STOP',
          desc: `The battery inverter is stopping.`,
        },
        8: {
          title: 'DISC',
          desc: `The capacitors on the AC and DC sides are discharging (discharge time is 1 minute).
          Please wait until discharge has been completed before performing any actions in the unit.`,
        },
        9: {
          title: 'FLT',
          desc: `There is a fault in the inverter. See fault code FXX and refer to section 3 for troubleshooting.`,
        },
        10: {
          title: 'LVRT',
          desc: `Algorithm Low Voltage Ride Through is running.`,
        },
        11: {
          title: 'OVRT',
          desc: `Algorithm Over Voltage Ride Through is running.`,
        },
        12: {
          title: 'NGHT',
          desc: `The inverter has detected night conditions.
          Characteristics: Battery Inverter - on. AC breakers - closed. DC switch disconnector - open. Battery contactors - open.`,
        },
        13: {
          title: 'NDCO',
          desc: `The battery inverter has detected it is night-time already, DC side is opened, but it is not performing heating or operating in night mode.`,
        },
        14: {
          title: 'STB',
          desc: `The battery inverter is waiting for the conditions to perform the soft charge to be fulfilled.`,
        },
        15: {
          title: 'HVPL',
          desc: `High Voltage phase lost.`,
        },
        17: {
          title: 'PRON',
          desc: `The inverter is in a status before "on" status, where after performing the soft charge, it checks the synchronization with the grid.`,
        },
        18: {
          title: 'DIAG',
          desc: `Extended self-diagnosis, in which the soft charge is done rectificating and then discharging each module sequentially. If this process works well, then the equipment will start.`,
        },
        19: {
          title: 'LCON',
          desc: `LC filter contactor has been activated and the batter inverter is waiting for the feedback to confirm the contactor is closed`,
        },
        20: {
          title: 'PRMG',
          desc: `Medium voltage transformer premagnetization is in progress.`,
        },
        21: {
          title: 'BBAL',
          desc: `Algorithm Bank Balancing is running.
          BESS controller will automatically react to voltage deviations between banks by adjusting (shifting) the power references relative to each battery bank to achieve again the same voltage levels.
          Characteristics: Inverter modules related to some banks - stop. AC breakers - closed.
          DC switch disconnector - closed. Batter contactors - closed.`,
        },
        22: {
          title: 'CVSB',
          desc: `Algorithm CV stand by is running.
          BESS controller system will react to keep constant voltage of the battery in order to hold the SOC and prevent SOC droop.`,
        },
      },
      warning: {
        '0': { title: 'None', desc: 'No abnormality' },
        '1': {
          title: 'HVAC',
          desc: 'The inverter input voltage is reaching a dangerous level. Its value is above the value set in the protections.',
        },
        '2': {
          title: 'LVAC',
          desc: 'The inverter input voltage is reaching a dangerous level. Its value is below the value set in the protections.',
        },
        '3': {
          title: 'HFRQ',
          desc: 'The input frequency is reaching a dangerous level. Its value is above the value set in the protections.',
        },
        '4': {
          title: 'LFRQ',
          desc: 'The input frequency is reaching a dangerous level. Its value is below the value set in the protections.',
        },
        '5': { title: 'NSC', desc: 'Start conditions are not fulfilled.' },
        '6': {
          title: 'NOMD',
          desc: 'Heating is not working because no modules are available.',
        },
        '7': { title: 'PLIM', desc: 'P limit has been reached.' },
        '8': { title: 'QLIM', desc: 'Q limit has been reached.' },
        '9': { title: 'ISLIM', desc: 'Is limit has been reached.' },
        '10': { title: 'IDCLIM', desc: 'Idc limit has been reached.' },
        '11': { title: 'PDVDC', desc: 'A derating is active.' },
        '12': { title: 'HEAT', desc: 'Heating is active.' },
        '13': { title: 'NIGHT', desc: 'Night mode is active.' },
        '14': {
          title: 'LP',
          desc: 'Active power is lower than the minimum AC power set on G2.2.2.',
        },
        '15': {
          title: 'LVDC',
          desc: 'DC voltage is lower than the minimum DC voltage set on G2.2.5.',
        },
        '16': {
          title: 'PDT',
          desc: 'Inverter is limiting power due to high internal temperature.',
        },
        '17': {
          title: 'PPF',
          desc: 'The Power Per Frequency algorithm is running. Power is reduced due to a high frequency.',
        },
        '19': {
          title: 'HVDC',
          desc: 'DC voltage is more than 1500V. A fault will be triggered if the delay time set in [G10.5.21 elapses.',
        },
        '20': {
          title: 'UMF',
          desc: 'Any of the modules has suffered a fault and it stop.',
        },
        '26': {
          title: 'LCFB',
          desc: 'LC contactors are not operating adequately in the stop procedure.',
        },
        '27': { title: 'QFV', desc: 'Grid support QV ongoing.' },
        '28': { title: 'PFV', desc: 'Grid support PV ongoing.' },
        '29': {
          title: 'VDCDS',
          desc: 'Discordance between bus voltage and input voltage.',
        },
        '40': {
          title: 'OVP',
          desc: 'PowerStorageSwitchAn overvoltage has been detected in the AC maneuver',
        },
        '41': {
          title: 'RTC',
          desc: 'The RTC is not configured. Set the time in G1.',
        },
        '42': {
          title: 'MSWM',
          desc: 'There are one or more modules with different software versions. Check the version of each module in SV9.',
        },
        '43': {
          title: 'VSWM',
          desc: 'Software versions are not compatible. Contact Power Electronics.',
        },
        '44': {
          title: 'NOSD',
          desc: 'No SD card detected. Verify that it is inserted and properly placed.',
        },
        '45': {
          title: 'BMI',
          desc: 'One or more modules of the equipment has been isolated due to a fault.',
        },
        '46': {
          title: 'DUIUN',
          desc: 'PowerStorageSwitchNote: This waring only applies if the inverter type is PCS. Not applicable to Multi PCSK. There is a current unbalance in the DU channels. By default',
        },
        '47': {
          title: 'SPRLD',
          desc: 'Some of the circuit breaker triggers are not loaded. This warning will only show up when the DU is configured with NEC 2017. Check wiring and breakers',
        },
        '50': {
          title: 'DUCOM',
          desc: 'There is a problem in the Disconnecting Unit communications.',
        },
        '51': {
          title: 'CHISL',
          desc: 'PowerStorageSwitchDU channel isolated because it is derived',
        },
        '52': {
          title: 'INSA',
          desc: 'PowerStorageSwitchDerived channels detection algorithm is running. Once finished',
        },
        '53': {
          title: 'DUOF',
          desc: 'Problem with feedback from one of the DU channels in an opening event.',
        },
        '54': {
          title: 'DUCF',
          desc: 'Problem with feedback from one of the DU channels in a closing event.',
        },
        '55': { title: 'OVDCP', desc: 'PowerStorageSwitchDC overeia tainer' },
        '56': { title: 'ISFLT', desc: 'Warning not used currently.' },
        '57': {
          title: 'CHCOM',
          desc: 'Communications problem between the Disconnecting Unit and acquisition channels',
        },
        '58': {
          title: 'NOCHN',
          desc: 'PowerStorageSwitchIndicates that there are no available channels. Similar to fault 50',
        },
        '59': {
          title: 'GRIDIN',
          desc: 'Indicates that the insulation fault has occurred in the AC side.',
        },
        '60': {
          title: 'DUBSY',
          desc: 'Contactors processing event ongoing. Disappears once finished.',
        },
        '61': {
          title: 'CHSWM',
          desc: 'DU acquisition channels with different versions have been detected.',
        },
        '63': {
          title: 'CHeD',
          desc: 'A current has been detected during a DU channels opening event.',
        },
        '64': {
          title: 'CHMW',
          desc: 'PowerStorageSwitchOne or more acquisition channels does not measure any voltage',
        },
        '100': {
          title: 'DCOMS',
          desc: 'Communication between the display and the control board has been lost.',
        },
        '130': {
          title: 'ISODCCM',
          desc: 'PowerStorageSwitchCommunication with the DC insulation monitor cannot be stablished. Check the RS485 cable or',
        },
        '131': {
          title: 'INSDC',
          desc: 'The DC insulation monitor has detected an insulation resistance below the threshold set in [G9.1.4].',
        },
        '132': {
          title: 'IRTHDC',
          desc: 'The DC insulation monitor has detected a higher insulation resistance than the admissible.',
        },
        '135': {
          title: 'ISOACCM',
          desc: 'PowerStorageSwitchCommunication with the AC insulation monitor cannot be stablished. Check the RS485 cable or',
        },
        '136': {
          title: 'INSAC',
          desc: 'The AC insulation monitor has detected an insulation resistance below the threshold set in [G9.2.4al.',
        },
        '137': {
          title: 'IRTHAC',
          desc: 'The AC insulation monitor has detected a higher insulation resistance than the admissible.',
        },
        '140': {
          title: 'FNIMCM',
          desc: 'Communication with the impulsion fans (cyclone) cannot be stablished.',
        },
        '141': {
          title: 'FNIMER',
          desc: 'The impulsion fan (cyclone) has reported an error.',
        },
        '142': {
          title: 'FNXCER',
          desc: 'Feedback from extraction fans in the low voltage side has not been received.',
        },
        '143': {
          title: 'ENXMER',
          desc: 'Feedback from extraction fans in the medium voltage side has not been received.',
        },
        '147': {
          title: 'BBON',
          desc: 'PowerStorageSwitchIs giving notice that BlackBox is being downloaded into the SD. While it is active',
        },
        '148': {
          title: 'DUFB1',
          desc: 'PowerStorageSwitchW148(x): The circuit breaker feedback signals are in a state that does not allow to distinguish whether it is open or closed',
        },
        '149': {
          title: 'DUFB2',
          desc: 'PowerStorageSwitchW149(x): The circuit breaker feedback signals are indicating that the circuit breaker is open but a close has been commanded',
        },
        '300': {
          title: 'ACTBAL',
          desc: 'PowerStorageSwitchThe equipment is in Active Balancing mode. Once finished',
        },
        '301': {
          title: 'RFIO',
          desc: 'A current limitation is performed to avoid exceeding the threshold.',
        },
        '350': {
          title: 'BMSA',
          desc: 'Battery Manage System is stopped because a BMS alarm is active. Check the BMS active alarms to restart.',
        },
        '351': {
          title: 'BMSIA',
          desc: 'PowerStorageSwitchBank in Partial Operation mode. There is imbalance inside the BMS',
        },
        '352': {
          title: 'SNTPF',
          desc: 'Synchronization of NTP server has failed.',
        },
        '353': {
          title: 'EMBD',
          desc: 'Battery bank is disabled by MPCSK. (IGBTs are stopped and DC breaker is open)',
        },
        '354': {
          title: 'BMSCL',
          desc: 'MPCSK has lost communication with BMS. Heartbeat time has been exceeded.',
        },
        '355': {
          title: 'BMSFE',
          desc: 'PowerStorageSwitchBMS fuse sensor fail. The input busbar and the DSP will be disabled',
        },
        '356': {
          title: 'BMSVSD',
          desc: 'PowerStorageSwitchBMS rack voltage sensor fail. The input busbar and the DSP will be disabled',
        },
        '357': {
          title: 'BMSFSC',
          desc: 'PowerStorageSwitchBMS rack current sensor fail. The input busbar and the DSP will be disabled',
        },
        '358': { title: 'BMSFF', desc: 'BMS fan fault.' },
        '359': {
          title: 'BMSSS',
          desc: 'PowerStorageSwitchBMS system stop bit is activated. The input busbar and the DSP will be disabled',
        },
        '360': {
          title: 'BMSSD',
          desc: 'BMS input status differs from last commanded (closing or opening of rack contactors. The status is checked in the system mode register (Idle / Ready Offline).',
        },
        '361': {
          title: 'BMSWC',
          desc: 'PowerStorageSwitchBMS wrong configuration',
        },
        '369': {
          title: 'HVACNOP',
          desc: 'One of the HVAC is not operational.',
        },
        '370': {
          title: 'ENCDORCN',
          desc: 'Indicates the container battery enclosure is open.',
        },
        '371': {
          title: 'ENCALARTR',
          desc: 'Enclosure fire system alarm / trouble. Check SV17.2 or SV17.5',
        },
        '372': {
          title: 'ENCDCDIS',
          desc: 'One of the container DC switch disconnectors is open.',
        },
        '373': {
          title: 'ENCHHUM',
          desc: 'High humidity inside the enclosure. SV17.12 is over G5.13.4 value.',
        },
        '374': {
          title: 'ENCUPSBAT',
          desc: 'UPS battery failure. Short when battery voltage falls below 21.9V (3%) or battery failure is observed through the battery test function.',
        },
        '390': {
          title: 'TRTEMP',
          desc: 'Transformer over oil temperature detected.',
        },
        '391': {
          title: 'TRPRESU',
          desc: 'Transformer over pressure detected.',
        },
        '392': { title: 'TROIL', desc: 'Transformer oil low level detected.' },
      },
      error: {
        '0': {
          title: 'None',
          desc: 'No abnormality',
          fault: 'None',
          actions: ['None'],
        },
        '1': {
          title: 'WD',
          desc: 'A fault in the DSP has occurred.',
          fault: 'F1: Watchdog',
          actions: [
            'Disconnect and reconnect the ancillary services supply.',
            `If the fault persists, request Power Electronics' technical assistance.`,
          ],
        },
        '2': {
          title: 'HWVBS',
          desc: 'DC overvoltage detected. Threshold: 1520Vdc',
          fault: 'F2: HW Vbus',
          actions: [
            'Check the voltage with an external voltmeter and the displayed voltage in the inverter.',
            'Check the DC voltage measurement fuses.',
          ],
        },
        '3': {
          title: 'SFTCHR',
          desc: 'Instant failure: Wrong configuration (central AC without DU…). Fault at 60s: Timeout, one of the soft charge processes did not end successfully',
          fault: 'F3: Softcharge',
          actions: [
            'Check the fuses.',
            'Check contactors and feedback.',
            'Check soft charge configuration and the required conditions for the desired soft charge.',
            'Request technical assistance.',
          ],
        },
        '4': {
          title: 'DISCHR',
          desc: 'Fault at 50s. Timeout',
          fault: 'F4: Discharge',
          actions: [
            'Check voltage in all modules and identify which module (or modules) did not discharge correctly.',
            'If a module is repeatedly triggering this fault, contact Power Electronics.',
            'It is known that depending on the number of modules available, the time is not enough. It is not important because the autoreset clears the fault and the second attempt ends the download.',
          ],
        },
        '5': {
          title: 'HGHVAC',
          desc: 'High AC voltage fault. 5 programmable levels with 5 programmable delays.',
          fault: 'F5: High Vac',
          actions: [
            'Check the voltage with an external voltmeter and the displayed voltage in the inverter.',
            'Check the AC voltage measurement fuses.',
            'Check wiring and connectors.',
            'Check G5.2 settings.',
            'Request technical assistance.',
          ],
        },
        '6': {
          title: 'LWVAC',
          desc: 'Low AC voltage fault. 5 programmable levels with 5 programmable delays.',
          fault: 'F6: Low Vac',
          actions: [
            'Check the voltage with an external voltmeter and the displayed voltage in the inverter.',
            'Check the AC voltage measurement fuses.',
            'Check wiring and connectors.',
            'Check G5.1 settings.',
            'Request technical assistance.',
          ],
        },
        '7': {
          title: 'HGHFRE',
          desc: 'High frequency fault. 5 programmable levels with 5 programmable delays.',
          fault: 'F7: High frequency',
          actions: [
            'Check the AC voltage measurement fuses.',
            'Check wiring and connectors.',
            'Check G5.4 settings.',
            'Request technical assistance.',
          ],
        },
        '8': {
          title: 'LWFRE',
          desc: 'Low frequency fault. 5 programmable levels with 5 programmable delays.',
          fault: 'F8: Low frequency',
          actions: [
            'Check the AC voltage measurement fuses.',
            'Check wiring and connectors.',
            'Check G5.3 settings.',
            'Request technical assistance.',
          ],
        },
        '9': {
          title: 'FPGACMS',
          desc: 'Very fast fault. 2 frames to the central FPGA are wrong.',
          fault: 'F9: FPGA comms',
          actions: [
            'Disconnect and reconnect the ancillary services supply.',
            'Check fiber optics wiring.',
            'If the fault persists, request Power Electronics technical assistance.',
          ],
        },
        '10': {
          title: 'MSTFPGA',
          desc: 'The central FPGA has not been configured correctly. Check that the 3 joined leds are turned on.',
          fault: 'F10: Master FPGA',
          actions: [
            'Disconnect and reconnect the ancillary services supply.',
            'Check fiber optics wiring.',
            'If the fault persists, request Power Electronics technical assistance.',
          ],
        },
        '13': {
          title: 'NOMOD',
          desc: 'All modules from the enabled busbars have stopped.',
          fault: 'F13: No modules enabled in the busbars enabled',
          actions: [
            'Check that all the enabled busbars has its respective modules enabled.',
            'Check the last module fault to identify the problem and take the necessary actions.',
            'Change AC HALL boards.',
          ],
        },
        '14': {
          title: 'DRVSLT',
          desc: 'Wrong drive select configuration.',
          fault: 'F14: Drive select',
          actions: ['Contact Power Electronics', 'Initialization of all parameters is recommended.'],
        },
        '15': {
          title: 'SYNC',
          desc: 'Wrong equipment configuration.',
          fault: 'F15: Synchronization',
          actions: [
            'Contact Power Electronics.',
            'In addition, an initialization of all parameters is recommended.',
          ],
        },
        '16': {
          title: 'DCMSW',
          desc: 'There is a problem with the DC measurement from central control. It occurs when the bus voltage is less than 100V during 30s.',
          fault: 'F16: DC miswired',
          actions: ['Check DU and central DC measurement.', 'Check wiring and fuses.'],
        },
        '17': {
          title: 'DUFB',
          desc: 'The DU is not closing when required.',
          fault: 'F17: DU feedback',
          actions: [
            'Check communications',
            'Check manually if the DU closes and opens correctly (no voltage test).',
            'Contact Power Electronics if the problem persists.',
          ],
        },
        '18': {
          title: 'ACSCFB',
          desc: 'Feedback from the soft charge contactor does not match the order during 5s.',
          fault: 'F18: ACSC feedback',
          actions: ['Check contactor wiring and feedback.'],
        },
        '19': {
          title: 'ACSCNV',
          desc: 'The central soft charge contactor is closed',
          fault: 'F19: ACSC no voltage',
          actions: [
            'Measure voltage 4s after closing the contactor. It should be at least 487 V DC.',
            'Measure soft charge ancillary voltage. It must be 690V.',
            'Check soft charge fuses, diodes and wiring.',
          ],
        },
        '20': {
          title: 'MODFLT',
          desc: 'Reserved fault for internal module.',
          fault: 'F20: Module fault',
          actions: ['Contact Power Electronics.'],
        },
        '21': {
          title: 'DUSTCK',
          desc: 'Voltage during soft charge process is anomalous when the DU is open.',
          fault: 'F21: DU stucked',
          actions: [
            'Check if when the DU is open there is no voltage in the inverter busbar.',
            'Contact Power Electronics if the problem persists',
          ],
        },
        '22': {
          title: 'MVF',
          desc: 'A medium voltage fuse has blown.',
          fault: 'F22: Medium V fuse',
          actions: [
            'See group G5.9 for information about HVPL algorithm.',
            'Check medium voltage inputs.',
            'Contact your electrical company.',
            'Contact Power Electronics if the problem persists.',
          ],
        },
        '23': {
          title: 'UNBVAC',
          desc: 'There is an abnormal AC unbalanced voltage. It is configured in group G5.5.',
          fault: 'F23: Unbalanced Vac',
          actions: [
            `Check inverter's voltage measurement shown in the display.`,
            'Check input voltage.',
            'Check inverter measurement wires and fuses.',
          ],
        },
        '24': {
          title: 'HVDC',
          desc: 'There is an overvoltage in the DC bus.',
          fault: 'F24: High Vdc',
          actions: [
            `Check inverter's voltage measurement shown in the display.`,
            'Check input voltage.',
            'Check inverter measurement wires and fuses.',
          ],
        },
        '25': {
          title: 'LVDC',
          desc: 'Voltage is too low (< Vac √ 2 - G5.10.16). Fault is delayed 1ms (not configurable).',
          fault: 'F25: Low Vdc',
          actions: ['Check central DC voltage measurement.'],
        },
        '27': {
          title: 'MSF',
          desc: 'The first N modules have failed consecutively during start. If any of the first N modules starts correctly',
          fault: 'F27: Modules start fault',
          actions: ['Check network conditions', 'Try restarting the modules one by one.'],
        },
        '28': {
          title: 'PSANTISL',
          desc: 'The anti-islanding algorithm (in mode Passive*) has detected an island condition. Fault produced when the frequency changes abruptly in a certain time.',
          fault: 'F28: Passive anti island',
          actions: [
            'Verify with the company if power was actually cut.',
            'If this fault is triggered continuously, it could be a false fault. Check the plant configuration and, if necessary, consult with Power Electronics.',
          ],
        },
        '29': {
          title: 'HWIDCDIF',
          desc: 'A current derivation has been detected on the panels side.',
          fault: 'F29:HW Idc Dif',
          actions: ['Contact Power Electronics.'],
        },
        '30': {
          title: 'MVCMS',
          desc: 'Failure in communication with the medium voltage measure board.',
          fault: 'F30: MV comms',
          actions: ['Check communication fibers and voltage supply.'],
        },
        '31': {
          title: 'DIAGTIM',
          desc: 'A fault has been triggered while performing the auto diagnosis process, e.g.: There is no DU or the bus loading time is excessive.',
          fault: 'F31: Selfdiagnosis Fault',
          actions: ['Contact Power Electronics.'],
        },
        '32': {
          title: 'ERMDDIAG',
          desc: 'A fault has been triggered while performing the auto diagnosis process after the equipment has stopped due to a special protection.',
          fault: 'F32: Error Mod Selfdiagnosis',
          actions: [`If the fault persists, request Power Electronics' technical assistance.`],
        },
        '33': {
          title: 'UNREC',
          desc: 'The inverter could not restart after the last grid event.',
          fault: 'F33: Unable Reconnect',
          actions: ['Contact Power Electronics. Check the settings of the LVRT algorithms.'],
        },
        '34': {
          title: 'HOMDIFLV',
          desc: 'A zero-sequence current (earth leakage) has been detected on the low voltage side of the transformer. If the current exceeds the threshold defined, this fault will be triggered.',
          fault: 'F34:Hom Diff LV',
          actions: ['Contact Power Electronics.'],
        },
        '35': {
          title: 'MVPRE',
          desc: 'There was a problem during MV transformer premagnetization. If the fault is slow (up to 100s after attempting to start premagnetization), it means the process started but could not be completed within the set timeout due to a problem.',
          fault: 'F35: MV Premag',
          actions: ['Contact Power Electronics.', 'Initialize parameters.'],
        },
        '36': {
          title: 'OVOLIRLV',
          desc: 'One of the current thresholds (overload, overcurrent or inrush) has been overcome in the low voltage side of the transfomer.',
          fault: 'F36:Ovc Inrush LV',
          actions: ['Contact Power Electronics.'],
        },
        '37': {
          title: 'OVOLIRMV',
          desc: 'One of the current thresholds (overload, overcurrent or inrush) has been overcome in the medium voltage side of the transfomer.',
          fault: 'F37:Ovc Inrush MV',
          actions: ['Contact Power Electronics.'],
        },
        '38': {
          title: 'CUROFFLV',
          desc: 'A current measure has been detected in the low voltage side of the transformer while the equipment is not running.',
          fault: 'F38:CurrentOff LV',
          actions: ['Contact Power Electronics.'],
        },
        '39': {
          title: 'CUROFFMV',
          desc: 'A current measure has been detected in the medium voltage side of the transformer while the equipment is not running.',
          fault: 'F39:CurrentOff MV',
          actions: ['Contact Power Electronics.'],
        },
        '40': {
          title: 'OVTMPACM',
          desc: 'Internal NTC temperature over the value defined.',
          fault: 'F40: AC mod overtemperature',
          actions: [
            'Check temperature. If it is out of specification, request technical assistance.',
            'Make sure there is nothing obstructing the cooling fans. Verify they rotate correctly.',
            'Verify ambient conditions are proper for the equipment',
          ],
        },
        '42': {
          title: 'DCCNTLCK',
          desc: 'Locked contactor in a DU opening process.',
          fault: 'F42: DC contactor locked',
          actions: [
            'Check which channel, or channels, is reporting that the contactor is open but according to feedback it is closed.',
            'Check feedback of DC contactor.',
          ],
        },
        '43': {
          title: 'EMRGSTP',
          desc: 'Stop pushbutton (at the Main control Cabinet door) has been pushed',
          fault: 'F43: Emergency stop',
          actions: [
            'Check the push button status at the Main Control Cabinet door.',
            'Check the DC disconnecting switch status.',
            'Check the wiring of the six-pin terminal block next to display connectors J28, J29 and J33.',
          ],
        },
        '44': {
          title: 'DRVSLT',
          desc: 'Drive select parameters out of range. The main board does not have the Drive Select properly programmed.',
          fault: 'F44: Drive select',
          actions: [
            'Check values.',
            'Ensure the software package installed is complete and compatible with the inverter.',
          ],
        },
        '45': {
          title: 'INSL',
          desc: 'IMI has detected an insulation problem. Fault produced when the isolation monitor measures an impedance value lower than the one configured in G9.2.2 (EMB1 - DC insulation)',
          fault: 'F45: Insulation',
          actions: ['Check if the DU has any isolated string. If so, check the insulation of those strings.'],
        },
        '46': {
          title: 'DTFLT',
          desc: 'Outof range parameters have been detected.',
          fault: 'F46: Data fault',
          actions: ['Contact Power Electronics.'],
        },
        '47': {
          title: 'WTCHDG',
          desc: 'A fault in the microcontroller has occurred.',
          fault: 'F47: Watchdog uP',
          actions: [
            'Disconnect and reconnect the ancillary services supply.',
            `If the fault persists, request Power Electronics' technical assistance.`,
            'If the fault persists, contact Power Electronics',
          ],
        },
        '48': {
          title: 'INTCMS',
          desc: 'Internal communication problem.',
          fault: 'F48: Internal comms',
          actions: [
            'Disconnect and reconnect the ancillary services supply.',
            'If the fault persists, contact Power Electronics.',
          ],
        },
        '49': {
          title: 'IMISLFT',
          desc: 'Periodic insulation monitoring devices auto test has finished with errors.',
          fault: 'F49: IMI autotest',
          actions: ['Check DU connections.', 'If the fault persists, replace the IMI.'],
        },
        '50': {
          title: 'NODUCNL',
          desc: 'All DU channels have been isolated',
          fault: 'F50: No DU channels',
          actions: [
            'Check all connections.',
            'Reset all modules',
            'If the problem persists, contact Power Electronics.',
          ],
        },
        '51': {
          title: 'PPCC',
          desc: 'PPC and inverter communication problem during the time configured in G6.3.2.',
          fault: 'F51: PPC comms',
          actions: [
            'Disconnect and reconnect the PPC. Check PPC connections.',
            'Check communications wiring',
          ],
        },
        '52': {
          title: 'SCDUFB',
          desc: 'This fault has been reserved for future use in version 1.5.0 Softcharge timeout has expired and no feedback was received. This failure occurs when the DU soft charge contactors are ordered to close and the contactors do not close.',
          fault: 'F52: Softcharge DU feedback',
          actions: ['Check wiring (relay and feedback).'],
        },
        '53': {
          title: 'EARTHL',
          desc: 'GFDI current has exceeded the threshold defined in G5.8.2 during the time set in G5.8.3.',
          fault: 'F53: GFDI current leak',
          actions: ['Check input insulation.'],
        },
        '54': {
          title: 'DUCNOV',
          desc: 'Overcurrent in one of the DU channels.',
          fault: 'F54: DU channel overcurrent',
          actions: [
            'Check DU fuses.',
            'Check connections between the DU and the strings.',
            'Check that the tightening torque of all the joints in that channel is adequate.',
          ],
        },
        '55': {
          title: 'EXT/OVGR',
          desc: 'Digital input 6 of the microcontroller is open. There is a problem with the external protections associated with this D16. The external OVGR relay has tripped.',
          fault: 'F55: External fault/OVGR',
          actions: ['Contact Power Electronics.'],
        },
        '56': {
          title: 'REMRSTP',
          desc: 'A remote emergency stop command has been received through Modbus communications.',
          fault: 'F56: Remote emergency stop',
          actions: [
            'Verify with your plant controller supplier to determine if the emergency stop was intended and the plant can be restarted.',
          ],
        },
        '57': {
          title: 'DUMWR',
          desc: 'A command to close the DU has been received',
          fault: 'F57: DU channel miswired',
          actions: ['Contact Power Electronics.'],
        },
        '58': {
          title: 'SWCMM',
          desc: 'The microcontroller and DSP software versions are not compatible.',
          fault: 'F58: Control SW mismatch',
          actions: ['Check and update, if necessary, the software version.'],
        },
        '59': {
          title: 'MODSWI',
          desc: 'Not all modules have the same software version.',
          fault: 'F59: Module SW mismatch',
          actions: [
            'Check the software version of each module in [SV9.17]. Please contact Power Electronics to assist you with your software update.',
          ],
        },
        '60': {
          title: 'MODHWI',
          desc: 'Not all modules have the same electronic board revision.',
          fault: 'F60: Module HW mismatch',
          actions: [
            'Disable the modules with mismatching boards to keep the inverter running and Contact Power Electronics.',
          ],
        },
        '61': {
          title: 'DUSD',
          desc: 'The DU smoke detector has been activated.',
          fault: 'F61: DU smoke detector',
          actions: ['In the case of false faults, check the status of the digital input at the DU.'],
        },
        '62': {
          title: 'DUCOM',
          desc: 'Communication between DU and central control has been lost.',
          fault: 'F62: DU comms',
          actions: ['Check wiring. For further assistance, contact Power Electronics.'],
        },
        '63': {
          title: 'GRNDCN',
          desc: 'The ground contactor is closed but the insulator detector detects that the pole is isolated.',
          fault: 'F63: Ground connection',
          actions: ['Check ground contactor.', 'Check insulation monitor.'],
        },
        '64': {
          title: 'INVMAC',
          desc: 'Invalid MAC address (00-00-00-00-00-00).',
          fault: 'F64: Invalid MAC',
          actions: [
            'Check the configuration of G6.2. Change MAC address in G6.5.5. If you require assistance, contact Power Electronics.',
          ],
        },
        '100': {
          title: 'DCOMS',
          desc: 'Communications between the display and the control board are lost.',
          fault: 'F100: Display comms',
          actions: [
            'This fault will automatically disappear once communications are restored. If it is triggered frequently, it is possible that the wiring cable used is not correct and introduces noise into the communications module. In this case, it is recommended to use a category 6 screened cable',
          ],
        },
        '140': {
          title: 'INDTHR',
          desc: 'Feedback has not been received from the LCL filter inductance thermal.',
          fault: 'F140: Inductance thermal',
          actions: [
            'Check position, ventilation and status of the coil.',
            'If the fault is frequent, check wiring.',
          ],
        },
        '141': {
          title: 'MVTT',
          desc: 'Temperature in the medium voltage transformer (PT100 readings) is too high.',
          fault: 'F141:MV transformer T°',
          actions: ['Check settings in G5.7.6 and G5.7.7.', 'Check ventilation airflow.'],
        },
        '142': {
          title: 'AFLV',
          desc: 'Airflow temperature in the low voltage side is too high.',
          fault: 'F142: Air flow  LV',
          actions: [
            'Check settings in G5.11.6 and G5.11.7.',
            'Check air inlets and outlets for possible obstructions.',
            'Check ventilation airflow in the low voltage side.',
          ],
        },
        '143': {
          title: 'AFMV',
          desc: 'Temperature in the medium voltage side airflow is too high.',
          fault: 'F143: Air flow MV',
          actions: [
            'Check settings in G5.11.4 and G5.11.5.',
            'Check air inlets and outlets for possible obstructions.',
            'Check ventilation airflow in the medium voltage side.',
          ],
        },
        '144': {
          title: 'FNIMER',
          desc: 'One of the impulsion fans (cyclone) has reported an error.',
          fault: 'F144: Impulsion fans',
          actions: [
            'Verify AC input voltage for the ventilation is correct.',
            'Verify fans are not obstructed and rotate smoothly.',
          ],
        },
        '145': {
          title: 'FNXCER',
          desc: 'Feedback from the low voltage extraction fans has not been received.',
          fault: 'F145: Extrac. Fans control',
          actions: [
            'Check low voltage fans supply and that the status of fault outout is "closed".',
            'If fans work properly, check feedback wiring.',
          ],
        },
        '146': {
          title: 'FNXMER',
          desc: 'Feedback from the medium voltage extraction fans has not been received.',
          fault: 'F146: Extrac. Fans MV',
          actions: [
            'Check medium voltage fans supply and that the status of fault output is "closed".',
            'If fans work properly, check feedback wiring.',
          ],
        },
        '147': {
          title: 'FNIMCM',
          desc: 'Communication with the impulsion fans (cyclones) cannot be stablished.',
          fault: 'F147: Impulsion comms',
          actions: [
            'Check operation of the other bus devices (DU. insulation monitor) and verify terminating resistors are only in the central DU.',
            'Check the RS485 cable.',
            'Connect a RS485 cable directlv between central control and the fan to verify if they communicate.',
            'Replace fan.',
            'If the problem persists, contact Power Electronics.',
          ],
        },
        '148': {
          title: 'MVTTC',
          desc: 'The temperature threshold of the medium voltage transformer (defined in G5.7.8) has been overcome.',
          fault: 'F148: MV Critical T° Trans',
          actions: [
            'Verify the MV switchgear protection has been activated. If the switchgear has not opened, check the temperature in the MV transformer cabinet and contact Power Electronics.',
          ],
        },
        '150': {
          title: 'CPMVO',
          desc: 'Protection contactor of the medium voltage cell is open.',
          fault: 'F150: MV Cell prot open',
          actions: [
            'Contact Power Electronics.',
            `Fault caused when the media cell's feedback indicates that the media cell has been opened.`,
            'This fault has to jump if the cell is opened by hand or by itself. Never skip this fault if the cell has been opened from the control.',
          ],
        },
        '151': {
          title: 'SDMV',
          desc: 'Medium voltage smoke detector is activated.',
          fault: 'F151: Smoke detector MV',
          actions: [
            'Contact Power Electronics.',
            'This fault has an additional action associated by default.',
          ],
        },
        '155': {
          title: 'PRDID',
          desc: 'The product identifier is not valid.',
          fault: 'F155:Product id',
          actions: ['Contact Power Electronics.'],
        },
        '181': {
          title: 'HOMDIFLV',
          desc: 'A zero-sequence current (earth leakage) has been detected on the medium voltage side of the transformer. If the current exceeds the threshold defined during the time set',
          fault: 'F181: Hom Diff MV',
          actions: ['Contact Power Electronics.'],
        },
        '182': {
          title: 'CRBALVMV',
          desc: 'A current unbalance has been detected between the low and medium voltage sides (measures do not match).',
          fault: 'F182:Curr Bal LVMV',
          actions: ['Contact Power Electronics.'],
        },
        '183': {
          title: 'NEGPWLV',
          desc: 'Active power in the low voltage side of the transformer has overcome the threshold set during the time adjusted.',
          fault: 'F183:Neg Pow LV',
          actions: [
            'This fault indicates there is a problem in the equipment. Check it and, if the fault persists, contact Power Electronics.',
          ],
        },
        '184': {
          title: 'NEGPWMV',
          desc: 'Reserved for future use.',
          fault: 'F184:Neg Pow MV',
          actions: ['Reserved for future use.'],
        },
        '185': {
          title: 'CRUNBALV',
          desc: 'The unbalance threshold between phases in the low voltage side of the transformer has been overcome. This fault usually appears whenever a grid event occurs',
          fault: 'F185:Cur Unbl LV',
          actions: ['Wait until the fault disappears. Should the fault persist, contact Power Electronics.'],
        },
        '186': {
          title: 'CRUNBAMV',
          desc: 'Similar to F185, but for currents on the medium voltage side of the transformer.',
          fault: 'F186:Cur Unbl MV',
          actions: ['Wait until the fault disappears. Should the fault persist, contact Power Electronics.'],
        },
        '187': {
          title: 'CRBLDSPMD',
          desc: 'Current measurement made by central control does not match the sum of measurements of all modules.',
          fault: 'F187:Cur Bal DSPMD',
          actions: ['Contact Power Electronics.'],
        },
        '188': {
          title: 'PWBALLVMV',
          desc: 'Reserved for future use.',
          fault: 'F188:Pow Bal LVMV',
          actions: ['Reserved for future use.'],
        },
        '189': {
          title: 'THDIMV',
          desc: 'The threshold of harmonic distortion (THDi), has been overcome during the timeout set intemally.',
          fault: 'F189:THDi MV',
          actions: ['Contact Power Electronics.'],
        },
        '190': {
          title: 'CPVF',
          desc: 'One of the protection thresholds set in group G5.4b has been overcome.',
          fault: 'F190:Comb prot Volt Frq',
          actions: [
            'Follow the actions depending on the threshold that has been overcome:',
            'For high AC voltage: see actions for fault F5',
            'For low AC voltage: see actions for fault F6.',
            'For high frequency: see actions for fault F7.',
            'For low frequency: see actions for fault F8.',
            'Contact Power Electronics if you require assistance.',
          ],
        },
        '191': {
          title: 'CBVDC',
          desc: 'Voltage in any of the Buses is too low and DU is closed. Like F25',
          fault: 'F191: Crit Vdc Batt',
          actions: ['Check SCADA operation.', 'Contact Power Electronics.'],
        },
        '300': {
          title: 'IEMB',
          desc: 'The difference between each input busbar voltage is higher than expected.',
          fault: 'F300: Unbalance Bank',
          actions: [
            'Algorithm was disable. Anyways, once the equipment is started, the bank voltage wil balance.',
          ],
        },
        '301': {
          title: 'RFIO',
          desc: 'The current through the RFI capacitor exceeds the limit.',
          fault: 'F301: RFI Overcurrent',
          actions: [
            'Check voltage references at input DC busbars. The maximum voltage difference between inputs should not exceed 10%.',
          ],
        },
        '350': {
          title: 'NOBNK',
          desc: 'No battery banks have been enabled. There is a mistake in the setting',
          fault: 'F350: No Banks Enabled',
          actions: ['Check settings in G15.1.2-BMS Banks Enabled.'],
        },
        '351': {
          title: 'ISOAC',
          desc: 'IMI has detected an AC insulation problem. Fault produced when the isolation monitor measures an impedance value lower than the one configurated in G9.1.2.',
          fault: 'F351: Insulation AC',
          actions: ['Check AC connections.'],
        },
        '352': {
          title: 'STIMIAC',
          desc: 'Periodic AC insulation monitoring devices autotest has finished with errors.',
          fault: 'F352: IMI AC autotest',
          actions: ['Check AC connections.', 'If the fault persists, replace the IMI.'],
        },
        '368': {
          title: 'HVACCOMMS',
          desc: 'Timeout communication fault with the HVAC control unit.',
          fault: 'F368: HVAC Comms',
          actions: ['Check connections and wires.', 'Check HVAC control unit is operational.'],
        },
        '369': {
          title: 'HVACNOP',
          desc: 'Two or more HVAC units are not operational',
          fault: 'F369: HVAC Not Operative',
          actions: [
            'Check the operation of HVAC and repair it if necessary.',
            'Wait until the fault disappears.',
          ],
        },
        '370': {
          title: 'ENCCOMMS',
          desc: 'TCP Modus communication timeout failure with the device (failure in address',
          fault: 'F370: Enclosure Comms',
          actions: ['Check connections and wires.', 'Check Enclosure control unit is operational.'],
        },
        '371': {
          title: 'ENCTEMPH',
          desc: 'Temperature in some of the enclosure sensors or the average temperature higher than expected.',
          fault: 'F371: Enclosure Temp High',
          actions: [
            'Check air inlets and outlets for possible obstructions.',
            'Check ventilation airflow.',
            'Verify ambient conditions are proper for the equipment',
            'Check temperatures (SV17.13-SV17.18). If any is out of specification, request technical assistance.',
          ],
        },
        '372': {
          title: 'ENCDIG',
          desc: 'Emergency stop press-button on battery enclosure has been pushed.',
          fault: 'F372: Enclosure Digital',
          actions: [
            'Once the emergency is over, remove the emergency press-button and restart the equipment.',
          ],
        },
        '373': {
          title: 'ENC24VDC',
          desc: 'Fault indicating that the 24Vdc voltage is out of range or the UPS is offline. The 24V voltage is out of range when it is out of 3% (21~29V).',
          fault: 'F373: Enclosure 24VDC',
          actions: ['Check the enclosure power supply.', 'Check UPS is working.'],
        },
        '374': {
          title: 'ENCPREDIS',
          desc: 'Fault indicating fire system is in gas pre-discharge mode.',
          fault: 'F374: Enclosure PreDischarge',
          actions: ['Contact Power Eletronics.'],
        },
        '375': {
          title: 'ENCAGREL',
          desc: 'Fault indicating fire system has acted.',
          fault: 'F375: Enclosure Agent Release',
          actions: ['Contact Power Electronics.'],
        },
        '376': {
          title: 'ENCTEMPL',
          desc: 'Average temperature in the enclosure is lower than expected',
          fault: 'F376: Enclosure Temp Low',
          actions: [
            'Check air inlets and outlets for possible obstructions.',
            'Check ventilation airflow.',
            'Verify ambient conditions are proper for the equipment',
            'Check temperature. If it is out of specification, request technical assistance.',
          ],
        },
        '390': {
          title: 'TRCOMMS',
          desc: 'Communication timeout failure with medium voltage transformer. Timeout failure configuration in G6.6.2.4.',
          fault: 'F390: Trans Comms',
          actions: ['Check connections and wires.', 'Check transformer control unit is operational.'],
        },
        '391': {
          title: 'TRTEMP',
          desc: 'Transformer over oil temperature alarm has been activated.',
          fault: 'F391: Trans Temp',
          actions: ['Check transformer cooling system.'],
        },
        '392': {
          title: 'TRPRESU',
          desc: 'Transformer over pressure alarm has been activated.',
          fault: 'F392: Trans Presure',
          actions: ['Check transformer pressure system.'],
        },
        '393': {
          title: 'TROIL',
          desc: 'Transformer oil low level alarm has been activated.',
          fault: 'F393: Trans Oil',
          actions: ['Check transformer oil level and refill'],
        },
      },
    },
    meter: {
      status: {
        0: {
          title: 'Normal',
          desc: 'Normal',
        },
        1: {
          title: 'Abnormal',
          desc: 'Abnormal',
        },
      },
    },
    controller: {
      mode: [
        { title: 'Standby', desc: '' },
        { title: 'Large Consumer', desc: '' },
        { title: 'Ancillary Service', desc: '' },
        { title: 'Peak Shaving', desc: '' },
        { title: 'dReg', desc: '' },
      ],
      status: {
        0: {
          title: 'Stopped',
          desc: '',
        },
        1: {
          title: 'Executing Startup Procedure',
          desc: '',
        },
        2: {
          title: 'Executing Shutdown Procedure',
          desc: '',
        },
        3: {
          title: 'Running',
          desc: '',
        },
      },
      warning: {
        0: {
          title: 'None',
          desc: '',
        },
        1: {
          title: 'Meter Disconnected',
          desc: '',
        },
        2: {
          title: 'PCS Disconnected',
          desc: '',
        },
        3: {
          title: 'Meter & PCS Disconnected',
          desc: '',
        },
        4: {
          title: 'BMS Disconnected',
          desc: '',
        },
        5: {
          title: 'Meter & BMS Disconnected',
          desc: '',
        },
        6: {
          title: 'PCS & BMS Disconnected',
          desc: '',
        },
        7: {
          title: 'Meter & PCS & BMS Disconnected',
          desc: '',
        },
      },
    },
    error: {
      noData: '(No data)',
      invalidInputValue: 'The applied value does not meet the specifications',
      text: 'error',
    },
  },
};

export default en;
