import { SvgIcon } from './type';

export default function StorageIcon(props: SvgIcon) {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Group">
        <g id="Group_2">
          <path
            id="Vector"
            d="M29.0169 15.7674C28.6803 15.7674 28.3812 15.5555 28.269 15.219C26.7484 10.5449 22.4232 7.40384 17.5123 7.40384C12.6013 7.40384 8.27613 10.5449 6.75548 15.219C6.61837 15.6303 6.18213 15.8547 5.75834 15.73C5.34702 15.5929 5.12261 15.1442 5.24726 14.7329C6.97981 9.41057 11.9033 5.83331 17.4998 5.83331C23.0963 5.83331 28.0198 9.41057 29.7523 14.7329C29.8894 15.1442 29.665 15.5929 29.2412 15.73C29.1789 15.755 29.0917 15.7674 29.0169 15.7674Z"
            fill="white"
          />
        </g>
        <path
          id="Vector_2"
          d="M15.6553 22.9345H1.42093C0.984675 22.9345 0.635742 22.5855 0.635742 22.1492C0.635742 21.713 0.984675 21.364 1.42093 21.364H15.6553C16.0916 21.364 16.4406 21.713 16.4406 22.1492C16.4406 22.5855 16.0916 22.9345 15.6553 22.9345Z"
          fill="white"
        />
        <path
          id="Vector_3"
          d="M33.5792 22.9345H19.3448C18.9086 22.9345 18.5596 22.5855 18.5596 22.1492C18.5596 21.713 18.9086 21.364 19.3448 21.364H33.5792C34.0155 21.364 34.3645 21.713 34.3645 22.1492C34.3645 22.5855 34.0155 22.9345 33.5792 22.9345Z"
          fill="white"
        />
        <path
          id="Vector_4"
          d="M24.0066 28.3939H11.0187C10.5824 28.3939 10.2334 28.0449 10.2334 27.6086C10.2334 27.1724 10.5824 26.8234 11.0187 26.8234H24.0066C24.4429 26.8234 24.7918 27.1724 24.7918 27.6086C24.7918 28.0449 24.4429 28.3939 24.0066 28.3939Z"
          fill="white"
        />
        <path
          id="Vector_5"
          d="M17.5001 24.7917C16.0418 24.7917 14.8701 23.6076 14.8701 22.1617C14.8701 20.7158 16.0542 19.5193 17.5001 19.5193C18.946 19.5193 20.1425 20.7034 20.1425 22.1617C20.1425 23.62 18.9584 24.7917 17.5001 24.7917ZM17.5001 21.0897C16.9143 21.0897 16.4406 21.5634 16.4406 22.1492C16.4406 22.7351 16.9143 23.2087 17.5001 23.2087C18.0859 23.2087 18.5595 22.7351 18.5595 22.1492C18.5595 21.5634 18.0859 21.0897 17.5001 21.0897Z"
          fill="white"
        />
        <path
          id="Vector_6"
          d="M16.3657 20.9651C16.0915 20.9651 15.8297 20.828 15.6801 20.5662L10.8315 12.1652C10.6196 11.7913 10.7442 11.3052 11.1181 11.0933C11.4921 10.8814 11.9782 11.0061 12.1901 11.38L17.0387 19.781C17.2506 20.1549 17.126 20.641 16.7521 20.8529C16.6399 20.9277 16.5028 20.9651 16.3657 20.9651Z"
          fill="white"
        />
        <path
          id="Vector_7"
          d="M17.5 35C7.85254 35 0 27.1474 0 17.5C0 7.85254 7.85254 0 17.5 0C27.1474 0 35 7.85254 35 17.5C35 27.1474 27.1599 35 17.5 35ZM17.5 1.58296C8.72505 1.58296 1.58293 8.72506 1.58293 17.5125C1.58293 26.2999 8.72505 33.442 17.5 33.442C26.2749 33.442 33.4295 26.2999 33.4295 17.5125C33.4295 8.72506 26.2874 1.58296 17.5 1.58296Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
