export const routeConfigs = {
  dashboard: 'dashboard',
  schedule: 'schedule',
  operate: 'operate',
  present: 'present',
  history: 'history',
  storage: 'storage',
  profile: 'profile',
  members: 'members',
  // report: 'report',
  // analysis: 'analysis',
};

export const authRoutes = [
  routeConfigs.schedule,
  routeConfigs.operate,
  routeConfigs.history,
  routeConfigs.storage,
  routeConfigs.profile,
  routeConfigs.members,
];
