import { useCallback, useEffect, useState } from 'react';

import { fetchLatestSysStatus } from 'apis/fetchLatestSysStatus';
import { IApiBase } from 'apis/types';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import useAppConfigStore from 'stores/useAppConfigStore';
import useLatestSysStatusStore from 'stores/useLatestSysStatusStore';
import { Compressor } from 'utils/Number/elastic';
import { Complment2Number } from 'utils/Number/parser';
import shallow from 'zustand/shallow';

export default function useLatestSysStatus() {
  const [duration, interval] = useAppConfigStore(
    (s) => [s.dataFetchDurationS, s.dataFetchIntervalMs],
    shallow
  );
  const [data, setData] = useState<IApiBase | undefined>();
  const [isError, setIsError] = useState<boolean>(false);
  const {
    isError: fetchError,
    data: fetchData,
    error,
    refetch,
  } = useQuery(['latestSysStatus', duration], () => fetchLatestSysStatus(duration), {
    refetchInterval: interval,
  });

  useEffect(() => {
    setData(fetchData);
  }, [fetchData]);
  useEffect(() => {
    setIsError(fetchError);
  }, [fetchError]);

  const latestSysStatusRefetch = useCallback(async () => {
    const { isError, data } = await refetch();
    setIsError(isError);
    setData(data);
  }, [refetch]);

  useEffect(() => {
    if (!isError && data) {
      useLatestSysStatusStore.setState({
        bms:
          data.data.bms && data.data.bms.length > 0
            ? {
                ...data.data.bms[data.data.bms.length - 1],
                system_soc: Compressor(data.data.bms[data.data.bms.length - 1].system_soc),
                system_soh: Compressor(data.data.bms[data.data.bms.length - 1].system_soh),
                system_voltage: Compressor(data.data.bms[data.data.bms.length - 1].system_voltage),
                system_current:
                  data.data.bms[data.data.bms.length - 1].system_current !== undefined
                    ? data.data.bms[data.data.bms.length - 1].system_current! - 20000
                    : undefined,
                sbmu01_soc: Compressor(data.data.bms[data.data.bms.length - 1].sbmu01_soc),
                sbmu02_soc: Compressor(data.data.bms[data.data.bms.length - 1].sbmu02_soc),
                sbmu03_soc: Compressor(data.data.bms[data.data.bms.length - 1].sbmu03_soc),
                sbmu04_soc: Compressor(data.data.bms[data.data.bms.length - 1].sbmu04_soc),
              }
            : {},
        pcs:
          data.data.pcs && data.data.pcs.length > 0
            ? {
                ...data.data.pcs[data.data.pcs.length - 1],
                p: Compressor(Complment2Number(data.data.pcs[data.data.pcs.length - 1].p), 100),
                power_factor: Compressor(data.data.pcs[data.data.pcs.length - 1].p, 1000),
                grid_active_power: Complment2Number(
                  data.data.pcs[data.data.pcs.length - 1].grid_active_power
                ),
                grid_reactive_power: Complment2Number(
                  data.data.pcs[data.data.pcs.length - 1].grid_reactive_power
                ),
                total_current_r: Complment2Number(data.data.pcs[data.data.pcs.length - 1].total_current_r),
                total_current_s: Complment2Number(data.data.pcs[data.data.pcs.length - 1].total_current_s),
                total_current_t: Complment2Number(data.data.pcs[data.data.pcs.length - 1].total_current_t),
              }
            : {},
        meter:
          data.data.meter && data.data.meter.length > 0
            ? {
                ...data.data.meter[data.data.meter.length - 1],
                total_watt: Compressor(data.data.meter[data.data.meter.length - 1].total_watt, 100),
              }
            : {},
        controller:
          data.data.controller && data.data.controller.length > 0
            ? {
                ...data.data.controller[data.data.controller.length - 1],
                max_soc: Compressor(data.data.controller[data.data.controller.length - 1].max_soc),
                min_soc: Compressor(data.data.controller[data.data.controller.length - 1].min_soc),
                hold_soc: Compressor(data.data.controller[data.data.controller.length - 1].hold_soc),
                base_hz: Compressor(data.data.controller[data.data.controller.length - 1].base_hz, 100),
                p: Compressor(data.data.controller[data.data.controller.length - 1].p, 100),
                soc_t1: Compressor(data.data.controller[data.data.controller.length - 1].soc_t1),
                soc_t2: Compressor(data.data.controller[data.data.controller.length - 1].soc_t2),
              }
            : {},
        timeStamp: data.data.meter
          ? dayjs(
              data.data.meter[data.data.meter.length - 1]?.timestamp,
              'YYYY-MM-DD HH:mm:ss ZZ UTC'
            ).valueOf()
          : undefined,
      });
    } else if (error) {
      console.error('in useLatestSysStatus error: ', error);
    }
  }, [data, error, isError]);

  return { latestSysStatusRefetch };
}
