import useAppConfig from 'hooks/useAppConfig';
import useLatestSysStatus from 'hooks/useLatestSysStatus';
import ErrorPage from 'pages/Error';
import { colors } from 'palette';
import { Outlet, useLocation } from 'react-router-dom';
import { authRoutes } from 'routes';
import useAppConfigStore from 'stores/useAppConfigStore';
import useUserStore from 'stores/useUserStore';

import { Grid, GridItem, useBreakpointValue } from '@chakra-ui/react';

import Header from './Header';
import NavHeader from './Nav/NavHeader';

export default function Root() {
  const horizontalPadding = useBreakpointValue(['16px', '70px']);

  // const bgPrimary = useColorModeValue('mainLight ', 'mainDark');
  const isFullScreen = useAppConfigStore((s) => s.isFullScreen);
  const isAuth = useUserStore((s) => s.isAuth);
  let currentPath = useLocation().pathname.replaceAll('/', '');

  function AppInit() {
    useAppConfig();
    useLatestSysStatus();
    return null;
  }

  return (
    <>
      <AppInit />
      <Grid
        templateAreas={
          !isFullScreen
            ? `"nav"
            "header"
            "main"
            "footer"`
            : `"main"`
        }
        gridTemplateRows={!isFullScreen ? '80px auto 1fr 50px' : '1fr'}
        // gridTemplateColumns={!isFullScreen ? '250px 1fr' : '1fr'}
        minH="100vh"
        color="blackAlpha.700"
        bg={colors.bgPrimary}
      >
        {!isFullScreen ? (
          <>
            <GridItem px={horizontalPadding} bg={colors.bgContent} area={'nav'}>
              <NavHeader />
            </GridItem>
            <GridItem px={horizontalPadding} bg={'transparent'} area={'header'}>
              <Header />
            </GridItem>
            {/* <GridItem pt={4} bg={navBg} area={'nav'}>
              <Nav />
            </GridItem> */}
            <GridItem px={horizontalPadding} bg={'transparent'} area={'main'}>
              {authRoutes.includes(currentPath) && !isAuth ? <ErrorPage /> : <Outlet />}
            </GridItem>
            {/* <GridItem py="4" px="6" bg={bgPrimary} area={'footer'} style={{ borderBottomLeftRadius: 25 }}>
              <Footer />
            </GridItem> */}
          </>
        ) : (
          <GridItem bg={'transparent'} area={'main'}>
            <Outlet />
          </GridItem>
        )}
      </Grid>
    </>
  );
}
