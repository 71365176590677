import { ILogoutRsps } from './types';

export async function muatateLogout(): Promise<ILogoutRsps> {
  const mutate = JSON.stringify({
    query: `
    mutation{
      logout{
        _id
      }
  }
    `,
  });
  const response = await fetch((process.env.REACT_APP_API_URL ?? '') + '/graphql/logout', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: mutate,
  });

  return await response.json();
}
