import { IconType } from 'react-icons/lib';
import { Link } from 'react-router-dom';
import screenfull from 'screenfull';
import useAppConfigStore from 'stores/useAppConfigStore';

import { HStack, Icon, Text } from '@chakra-ui/react';

export default function NavItem({
  title,
  icon,
  routeName,
  disabled,
  fullScreen,
}: {
  title: string;
  icon: IconType;
  routeName: string;
  disabled?: boolean;
  fullScreen?: boolean;
}) {
  return (
    <Link
      style={{ width: 'fit-content' }}
      to={routeName}
      onClick={() => {
        useAppConfigStore.setState({ isFullScreen: fullScreen ?? false });
        if (fullScreen && screenfull.isEnabled) {
          screenfull.request();
        }
      }}
    >
      <HStack spacing={['15px', '30px']}>
        <Icon as={icon} w={['17px', '35px']} h={['17px', '35px']} />
        <Text color="#fff" fontWeight={'bold'} fontSize={['20px', '40px']}>
          {title}
        </Text>
      </HStack>
    </Link>
  );
}
