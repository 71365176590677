import { SvgIcon } from './type';

export default function ScheduleIcon(props: SvgIcon) {
  return (
    <svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Group">
        <path
          id="Vector"
          d="M29.4868 35H4.0663C1.82111 35 0 33.1789 0 30.9337V6.41127C0 4.16608 1.82111 2.34497 4.0663 2.34497C4.51534 2.34497 4.87709 2.70669 4.87709 3.15573C4.87709 3.60476 4.51534 3.96652 4.0663 3.96652C2.71919 3.96652 1.62159 5.06416 1.62159 6.41127V30.9337C1.62159 32.2808 2.71919 33.3785 4.0663 33.3785H29.4868C30.8339 33.3785 31.9316 32.2808 31.9316 30.9337V6.41127C31.9316 5.06416 30.8339 3.96652 29.4868 3.96652C29.0378 3.96652 28.6761 3.60476 28.6761 3.15573C28.6761 2.70669 29.0378 2.34497 29.4868 2.34497C31.732 2.34497 33.5531 4.16608 33.5531 6.41127V30.9337C33.5531 33.1789 31.732 35 29.4868 35Z"
          fill="white"
        />
        <path
          id="Vector_2"
          d="M23.1002 3.96652H10.4523C10.0033 3.96652 9.6416 3.60476 9.6416 3.15573C9.6416 2.70669 10.0033 2.34497 10.4523 2.34497H23.1002C23.5493 2.34497 23.911 2.70669 23.911 3.15573C23.911 3.60476 23.5493 3.96652 23.1002 3.96652Z"
          fill="white"
        />
        <path
          id="Vector_3"
          d="M28.0395 17.5H5.52522C5.06371 17.5 4.68945 17.1258 4.68945 16.6643C4.68945 16.2028 5.06371 15.8286 5.52522 15.8286H28.0395C28.501 15.8286 28.8751 16.2028 28.8751 16.6643C28.8751 17.1258 28.501 17.5 28.0395 17.5Z"
          fill="white"
        />
        <path
          id="Vector_4"
          d="M28.0395 11.5503H5.52522C5.06371 11.5503 4.68945 11.176 4.68945 10.7145C4.68945 10.253 5.06371 9.87885 5.52522 9.87885H28.0395C28.501 9.87885 28.8751 10.253 28.8751 10.7145C28.8751 11.176 28.501 11.5503 28.0395 11.5503Z"
          fill="white"
        />
        <path
          id="Vector_5"
          d="M28.0395 29.412H5.52522C5.06371 29.412 4.68945 29.0378 4.68945 28.5763C4.68945 28.1148 5.06371 27.7406 5.52522 27.7406H28.0395C28.501 27.7406 28.8751 28.1148 28.8751 28.5763C28.8751 29.0378 28.501 29.412 28.0395 29.412Z"
          fill="white"
        />
        <path
          id="Vector_6"
          d="M28.0395 23.4622H5.52522C5.06371 23.4622 4.68945 23.088 4.68945 22.6265C4.68945 22.165 5.06371 21.7908 5.52522 21.7908H28.0395C28.501 21.7908 28.8751 22.165 28.8751 22.6265C28.8751 23.088 28.501 23.4622 28.0395 23.4622Z"
          fill="white"
        />
        <path
          id="Vector_7"
          d="M7.35913 6.31147C6.89761 6.31147 6.52344 5.9373 6.52344 5.47578V0.835726C6.52344 0.374215 6.89761 0 7.35913 0C7.82064 0 8.19481 0.374215 8.19481 0.835726V5.47578C8.19481 5.9373 7.82064 6.31147 7.35913 6.31147Z"
          fill="white"
        />
        <path
          id="Vector_8"
          d="M26.1443 6.31147C25.6828 6.31147 25.3086 5.9373 25.3086 5.47578V0.835726C25.3086 0.374215 25.6828 0 26.1443 0C26.6058 0 26.98 0.374215 26.98 0.835726V5.47578C26.9675 5.9373 26.5933 6.31147 26.1443 6.31147Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
