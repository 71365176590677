import { colors } from 'palette';

import { popoverAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);
const selector = definePartsStyle({
  content: defineStyle({
    width: 'fit-content',
    padding: 4,
    bg: colors.appBlue,
    borderRadius: '25px',
    border: 'none',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  }),
});
export const popoverTheme = defineMultiStyleConfig({
  variants: { selector },
});
