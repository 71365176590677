import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { ToastId, useToast as _useToast, UseToastOptions } from '@chakra-ui/react';

export default function useToast(): [
  UseToastOptions | undefined,
  Dispatch<SetStateAction<UseToastOptions | undefined>>,
  ToastId | null
] {
  const [state, setState] = useState<UseToastOptions>();
  const [toastId, setToastId] = useState<ToastId | null>(null);
  const toast = _useToast();

  useEffect(() => {
    if (state) {
      if (state.id) {
        toast.update(state.id, { ...state });
      } else {
        const tId = toast({
          title: state.title ?? '',
          description: state.description ?? '',
          status: state.status ?? 'info',
          duration: state.duration,
          position: state.position ?? 'bottom-right',
          isClosable: state.isClosable ?? true,
          render: state.render,
          onCloseComplete: () => {
            state.onCloseComplete?.();
            setToastId(null);
          },
        });
        setToastId(tId);
      }
    }
  }, [state, toast]);

  return [state, setState, toastId];
}
