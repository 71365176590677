/* eslint-disable react/jsx-pascal-case */

import { colors } from 'palette';

import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  Modal as _Modal,
  ModalBody as _ModalBody,
  ModalCloseButton as _ModalCloseButton,
  ModalContent as _ModalContent,
  ModalFooter as _ModalFooter,
  ModalOverlay as _ModalOverlay,
  ModalProps,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';

export interface IModal extends Omit<ModalProps, 'onClose'> {
  header?: string | React.ReactNode;
  footer?: string | React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  children: React.ReactNode | JSX.Element | string;
  hasCloseBtn?: boolean;
}

function Header({ children }: { children: React.ReactNode }) {
  return (
    <Text color={colors.textPrimary} fontSize={'xl'} fontWeight={'bold'} pb={'20px'}>
      {children}
    </Text>
  );
}

export default function Modal({
  header,
  footer,
  isOpen,
  onClose = () => {},
  children,
  hasCloseBtn = false,
  ...rest
}: IModal) {
  const isMobile = useBreakpointValue([true, false]);
  if (!isMobile) {
    return (
      <_Modal isOpen={isOpen} onClose={onClose} {...rest}>
        <_ModalOverlay />
        <_ModalContent maxW={'550px'} p={'50px'} borderRadius={'25px'}>
          <Flex direction="column" gap={'10px'}>
            {header && <Header>{header}</Header>}
            {hasCloseBtn && <_ModalCloseButton />}
            <_ModalBody px={0} py={'5px'} color={colors.textPrimary} fontWeight={'normal'} fontSize={'xl'}>
              {children}
            </_ModalBody>
            {footer && (
              <_ModalFooter h={'65px'} px={0} pt={'20px'} pb={'5px'}>
                {footer}
              </_ModalFooter>
            )}
          </Flex>
        </_ModalContent>
      </_Modal>
    );
  } else {
    return (
      <Drawer placement={'bottom'} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent pt={5} pb={8} px={8} borderTopLeftRadius={'25px'} borderTopRightRadius={'25px'}>
          <Flex justify={'center'} align={'center'} pb={5}>
            <Box rounded={'full'} w={'50px'} h={1} bg={colors.appGray} />
          </Flex>
          {header && <Header>{header}</Header>}
          <DrawerBody px={0} pt={'5px'} color={colors.textPrimary} fontWeight={'normal'} fontSize={'xl'}>
            {children}
          </DrawerBody>
          {footer && (
            <DrawerFooter px={0} pt={'20px'} pb={0}>
              {footer}
            </DrawerFooter>
          )}
        </DrawerContent>
      </Drawer>
    );
  }
}
