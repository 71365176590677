import { SvgIcon } from './type';

export default function OperateIcon(props: SvgIcon) {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Group">
        <path
          id="Vector"
          d="M30.7584 35H4.24164C1.89964 35 0 33.0482 0 30.6418V4.3583C0 1.95189 1.89964 0 4.24164 0H30.7584C33.1004 0 35 1.95189 35 4.3583V30.6418C35 33.0482 33.1004 35 30.7584 35ZM4.24164 1.73795C2.83644 1.73795 1.69151 2.91445 1.69151 4.3583V30.6418C1.69151 32.0856 2.83644 33.2621 4.24164 33.2621H30.7584C32.1636 33.2621 33.3086 32.0856 33.3086 30.6418V4.3583C33.3086 2.91445 32.1636 1.73795 30.7584 1.73795H4.24164Z"
          fill="white"
        />
        <path
          id="Vector_2"
          d="M7.22173 27.5535C6.75333 27.5535 6.37598 27.1658 6.37598 26.6845V10.3075C6.37598 9.82621 6.75333 9.43848 7.22173 9.43848C7.69013 9.43848 8.06741 9.82621 8.06741 10.3075V26.6845C8.08042 27.1658 7.69013 27.5535 7.22173 27.5535Z"
          fill="white"
        />
        <path
          id="Vector_3"
          d="M7.24749 10.8957C5.64712 10.8957 4.33301 9.55886 4.33301 7.9011C4.33301 6.24335 5.63411 4.90646 7.24749 4.90646C8.86087 4.90646 10.1621 6.24335 10.1621 7.9011C10.1621 9.55886 8.86087 10.8957 7.24749 10.8957ZM7.24749 6.69786C6.59693 6.69786 6.07654 7.23265 6.07654 7.9011C6.07654 8.56955 6.59693 9.10426 7.24749 9.10426C7.89805 9.10426 8.41853 8.56955 8.41853 7.9011C8.41853 7.23265 7.89805 6.69786 7.24749 6.69786Z"
          fill="white"
        />
        <path
          id="Vector_4"
          d="M28.468 24.8664C27.9216 24.8664 27.4922 24.4118 27.4922 23.8637V7.88774C27.4922 7.32625 27.9346 6.88507 28.468 6.88507C29.0145 6.88507 29.4439 7.33962 29.4439 7.88774V23.8637C29.4569 24.4118 29.0145 24.8664 28.468 24.8664Z"
          fill="white"
        />
        <path
          id="Vector_5"
          d="M28.4682 29.1043C26.8678 29.1043 25.5537 27.7674 25.5537 26.1096C25.5537 24.4653 26.8548 23.115 28.4682 23.115C30.0686 23.115 31.3828 24.4519 31.3828 26.1096C31.3828 27.754 30.0816 29.1043 28.4682 29.1043ZM28.4682 24.9064C27.8176 24.9064 27.2972 25.4412 27.2972 26.1096C27.2972 26.7781 27.8176 27.3128 28.4682 27.3128C29.1188 27.3128 29.6392 26.7781 29.6392 26.1096C29.6392 25.4412 29.1188 24.9064 28.4682 24.9064Z"
          fill="white"
        />
        <path
          id="Vector_6"
          d="M14.3254 27.6872C13.779 27.6872 13.3496 27.2327 13.3496 26.6845V22.9011C13.3496 22.3396 13.792 21.8984 14.3254 21.8984C14.8719 21.8984 15.3013 22.353 15.3013 22.9011V26.6845C15.3013 27.2327 14.8719 27.6872 14.3254 27.6872Z"
          fill="white"
        />
        <path
          id="Vector_7"
          d="M14.3256 23.5829C12.7252 23.5829 11.4111 22.246 11.4111 20.5883C11.4111 18.9305 12.7122 17.5936 14.3256 17.5936C15.926 17.5936 17.2401 18.9305 17.2401 20.5883C17.2401 22.246 15.926 23.5829 14.3256 23.5829ZM14.3256 19.385C13.6751 19.385 13.1547 19.9198 13.1547 20.5883C13.1547 21.2567 13.6751 21.7914 14.3256 21.7914C14.9762 21.7914 15.4966 21.2567 15.4966 20.5883C15.4966 19.9198 14.9762 19.385 14.3256 19.385Z"
          fill="white"
        />
        <path
          id="Vector_8"
          d="M14.3254 19.2112C13.779 19.2112 13.3496 18.7566 13.3496 18.2085V7.87432C13.3496 7.31282 13.792 6.87164 14.3254 6.87164C14.8719 6.87164 15.3013 7.32619 15.3013 7.87432V18.2085C15.3013 18.77 14.8719 19.2112 14.3254 19.2112Z"
          fill="white"
        />
        <path
          id="Vector_9"
          d="M21.4036 12.6604C20.8571 12.6604 20.4277 12.2059 20.4277 11.6578V7.87432C20.4277 7.31282 20.8701 6.87164 21.4036 6.87164C21.937 6.87164 22.3794 7.32619 22.3794 7.87432V11.6578C22.3794 12.2059 21.937 12.6604 21.4036 12.6604Z"
          fill="white"
        />
        <path
          id="Vector_10"
          d="M21.4037 16.9519C19.8034 16.9519 18.4893 15.615 18.4893 13.9572C18.4893 12.3129 19.7904 10.9626 21.4037 10.9626C23.0171 10.9626 24.3182 12.2995 24.3182 13.9572C24.3182 15.615 23.0041 16.9519 21.4037 16.9519ZM21.4037 12.7674C20.7532 12.7674 20.2328 13.3022 20.2328 13.9706C20.2328 14.6391 20.7532 15.1739 21.4037 15.1739C22.0543 15.1739 22.5748 14.6391 22.5748 13.9706C22.5748 13.3022 22.0413 12.7674 21.4037 12.7674Z"
          fill="white"
        />
        <path
          id="Vector_11"
          d="M21.4036 27.6872C20.8571 27.6872 20.4277 27.2327 20.4277 26.6845V16.3503C20.4277 15.7888 20.8701 15.3477 21.4036 15.3477C21.937 15.3477 22.3794 15.8022 22.3794 16.3503V26.6845C22.3794 27.2327 21.937 27.6872 21.4036 27.6872Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
