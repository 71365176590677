import dayjs from 'dayjs';

import { IApiBase } from './types';

export async function fetchLatestSregCount(duration: number): Promise<IApiBase> {
  const currentTime = dayjs();
  const startTime = currentTime.subtract(duration, 'second');
  const queryTime = `from:${startTime.unix()} to: ${currentTime.unix()}`;
  const controllerQueryParamString = `controller(${queryTime} step:0){sreg_count mode}`;
  const response = await fetch(
    (process.env.REACT_APP_API_URL ?? '') +
      '/graphql?' +
      new URLSearchParams('query={' + controllerQueryParamString + '}')
  );

  return await response.json();
}
