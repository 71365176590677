import { colors } from 'palette';

import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(menuAnatomy.keys);

const userVariant = definePartsStyle((props) => {
  return {
    button: {
      _hover: {
        bg: 'transparent',
      },
      _active: {
        bg: 'trasparent',
      },
      _focus: {
        bg: 'trasparent',
      },
      _expanded: {
        bg: 'trasparent',
      },
    },
    item: {
      color: mode(colors.textSecondary, colors.textSecondary)(props),
    },
  };
});

const variants = {
  user: userVariant,
};

export const menuThemes = defineMultiStyleConfig({ variants });
