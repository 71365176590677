import DashboardIcon from 'assets/images/DashboardIcon';
import HistoryIcon from 'assets/images/HistoryIcon';
import OperateIcon from 'assets/images/OperateIcon';
import PresentIcon from 'assets/images/PresentIcon';
import ScheduleIcon from 'assets/images/ScheduleIcon';
import StorageIcon from 'assets/images/StorageIcon';
import TenmaLogo from 'assets/images/TenmaLogo';
import { colors } from 'palette';
import { useTranslation } from 'react-i18next';
import { BsFillSunFill } from 'react-icons/bs';
import useUserStore from 'stores/useUserStore';

import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  useBreakpointValue,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';

import LanguageMenu from './LanguageMenu';
import NavItem from './NavItem';

export default function NavHeader() {
  const { t } = useTranslation();
  const isAuth = useUserStore((s) => s.isAuth);
  const { toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const iconSize = useBreakpointValue([17, 35]);
  const drawerBg = useColorModeValue('rgba(38, 39, 54, 0.90)', 'rgba(26, 26, 45, 0.90)');

  return (
    <Flex height="100%" justify={'space-between'} align="center" color={colors.textSecondary}>
      <IconButton
        w={['30px', '60px']}
        h={['30px', '60px']}
        aria-label="nav"
        icon={<HamburgerIcon w={['20px', '40px']} h={['20px', '40px']} color={colors.navHeaderIcon} />}
        bg="transparent"
        borderRadius="10px"
        border={`1px solid ${colors.borderPrimary}`}
        onClick={onOpen}
      />
      <TenmaLogo width={'200px'} />
      <HStack>
        <LanguageMenu />
        <IconButton
          w={['30px', '60px']}
          h={['30px', '60px']}
          aria-label="nav"
          icon={
            <BsFillSunFill
              style={{
                width: iconSize,
                height: iconSize,
                color: colors.navHeaderIcon,
              }}
            />
          }
          bg="transparent"
          borderRadius="10px"
          border={`1px solid ${colors.borderPrimary}`}
          onClick={toggleColorMode}
        />
      </HStack>
      <Drawer placement={'left'} onClose={onClose} isOpen={isOpen} size={['xs', 'md']}>
        <DrawerOverlay />
        <DrawerContent
          borderTopRightRadius={'25px'}
          borderBottomRightRadius={'25px'}
          bg={drawerBg}
          px={['25px', '50px']}
          pt={['60px', '125px']}
        >
          <Flex direction={'column'} gap={['25px', '50px']}>
            <NavItem title={t('nav.dashboard')} icon={DashboardIcon} routeName="dashboard" />
            {isAuth && <NavItem title={t('nav.storage')} icon={StorageIcon} routeName="storage" />}
            {isAuth && <NavItem title={t('nav.schedule')} icon={ScheduleIcon} routeName="schedule" />}
            {isAuth && <NavItem title={t('nav.operate')} icon={OperateIcon} routeName="operate" />}
            {isAuth && <NavItem title={t('nav.history')} icon={HistoryIcon} routeName="history" />}

            <NavItem title={t('nav.present')} icon={PresentIcon} routeName="present" fullScreen={true} />
            {/* <NavItem title={t('nav.report')} icon={AiFillFile} disabled={true} routeName="report" /> */}
            {/* <NavItem title={t('nav.analysis')} icon={AiFillFund} disabled={true} routeName="analysis" /> */}
          </Flex>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}
