import { IUser } from 'apis/types';

export const UserInfoParser = (userInfo: IUser) => {
  const { username, email, role } = userInfo;
  return {
    username,
    email,
    role,
  };
};

type Role = 'root' | 'admin' | 'guest' | string | undefined;

export const IsAuthUser = (userRole: Role) => {
  if (userRole === 'admin' || userRole === 'root') {
    return true;
  }
  return false;
};

export const IsRootUser = (userRole: Role) => {
  if (userRole === 'root') {
    return true;
  }
  return false;
};
