import { useEffect, useState } from 'react';

import Heading from 'components/Heading';
import { useNavigate } from 'react-router-dom';

export default function ErrorPage() {
  const [countDown, setCountDown] = useState<number>(3);
  const navigate = useNavigate();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate('/dashboard');
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [navigate]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountDown((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <Heading title={`Oops, Access forbidden, redirect to dashboard in ${countDown >= 0 ? countDown : 0}`} />
  );
}
