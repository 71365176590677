import { useCallback, useState } from 'react';

import { mutateLogin } from 'apis/mutateLogin';
import { ILoginRsps } from 'apis/types';
import { FormLabel } from 'components/Form';
import Heading from 'components/Heading';
import Modal from 'components/Modal';
import { Field, FieldInputProps, Form, Formik, FormikProps } from 'formik';
import { colors } from 'palette';
import { useTranslation } from 'react-i18next';
import { BiSolidHide, BiSolidShow } from 'react-icons/bi';
import { useMutation } from 'react-query';
import useUserStore from 'stores/useUserStore';

import { EmailIcon, LockIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  ScaleFade,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { loginSchema } from '../utils';

interface LoginModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function LoginModal({ isOpen, onClose }: LoginModalProps) {
  const { t } = useTranslation();
  const [showPwd, setShowPwd] = useState(false);
  const LoginMutation = useMutation(mutateLogin);
  const { isOpen: loginFailed, onOpen: loginFailedOpen, onClose: loginFailedClose } = useDisclosure();
  const setUserInfo = useUserStore((s) => s.setUserInfo);

  const onLoginError = useCallback(
    (error: unknown) => {
      console.log('login error:', error);
      loginFailedOpen();
      setTimeout(() => {
        loginFailedClose();
      }, 8000);
    },
    [loginFailedClose, loginFailedOpen]
  );
  const onLoginSuccess = useCallback(
    (data: ILoginRsps) => {
      if (data.errors !== undefined || !data.data.login) {
        onLoginError(data.errors);
        return;
      }
      setUserInfo(data.data.login);
      onClose();
    },
    [onClose, onLoginError, setUserInfo]
  );

  const onPwdShowToggle = () => {
    setShowPwd((p) => !p);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} hasCloseBtn={false}>
      <Flex justify={'center'} mb={5}>
        <Heading size={'xl'} title={t('header.login.title')} />
      </Flex>
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={(values) => {
          LoginMutation.mutate(values, {
            onSuccess: onLoginSuccess,
            onError: onLoginError,
          });
        }}
        validationSchema={loginSchema}
      >
        {(props) => (
          <Form>
            <Flex direction={'column'} gap={5}>
              <Field name="email">
                {({
                  field,
                  form,
                }: {
                  field: FieldInputProps<string>;
                  form: FormikProps<{ email: string; password: string }>;
                }) => (
                  <FormControl isInvalid={Boolean(form.errors.email)}>
                    <FormLabel>{t('header.login.email')}</FormLabel>
                    <InputGroup>
                      <InputLeftElement>{<EmailIcon w={5} h={5} />}</InputLeftElement>
                      <Input type={'text'} placeholder={t('header.login.emailHint') ?? ''} {...field} />
                    </InputGroup>

                    <FormErrorMessage>{props.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="password">
                {({
                  field,
                  form,
                }: {
                  field: FieldInputProps<string>;
                  form: FormikProps<{ email: string; password: string }>;
                }) => (
                  <FormControl isInvalid={Boolean(form.errors.password)}>
                    <FormLabel>{t('header.login.password')}</FormLabel>
                    <InputGroup>
                      <InputLeftElement>{<LockIcon w={5} h={5} />}</InputLeftElement>
                      <Input
                        type={showPwd ? 'text' : 'password'}
                        placeholder={t('header.login.passwordHint') ?? ''}
                        {...field}
                      />
                      <InputRightElement onClick={onPwdShowToggle} cursor="pointer">
                        {!showPwd ? (
                          <Icon w={6} h={6} as={BiSolidShow} />
                        ) : (
                          <Icon w={6} h={6} as={BiSolidHide} />
                        )}
                      </InputRightElement>
                    </InputGroup>

                    <FormErrorMessage>{props.errors.password}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Flex justify={'center'} my="16px">
              <ScaleFade initialScale={0.9} in={loginFailed}>
                <Text color={colors.dangerStatus}>{t('header.login.errorMsg.failed')}</Text>
              </ScaleFade>
            </Flex>
            <Button
              variant={'main'}
              w="100%"
              type="submit"
              isDisabled={
                Boolean(props.errors.email) || Boolean(props.errors.password) || LoginMutation.isLoading
              }
            >
              {Boolean(props.errors.email) || Boolean(props.errors.password)
                ? t('header.login.errorMsg.needFix')
                : t('header.login.login')}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
