import { colors } from 'palette';

import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys);
const main = definePartsStyle({
  label: {
    fontSize: '20px',
    color: colors.textPrimary,
    fontWeight: 'bold',
  },
  control: {
    boxSize: '26px',
    borderColor: colors.textPrimary,
    _checked: {
      borderColor: colors.textPrimary,
    },
  },
  icon: {
    color: colors.textPrimary,
    width: '26px',
    height: '26px',
  },
});
export const checkboxTheme = defineMultiStyleConfig({
  variants: { main },
});
