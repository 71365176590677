import { useMemo } from 'react';

// import Button, { buttonType } from 'components/Button';
import dayjs from 'dayjs';
import { colors } from 'palette';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { routeConfigs } from 'routes';
import useLatestSysStatusStore from 'stores/useLatestSysStatusStore';
import useUserStore from 'stores/useUserStore';
import shallow from 'zustand/shallow';

import { Avatar, Box, Button, Flex, HStack, Text, useBreakpointValue, useDisclosure } from '@chakra-ui/react';

import LoginModal from './components/LoginModal';
import { Sreg } from './components/SregStatus';
import MenuUser from './components/UserMenu';

function Title({ children }: { children: React.ReactNode }) {
  return (
    <Text color={colors.textPrimary} fontSize={'40px'} fontWeight={'bold'}>
      {children}
    </Text>
  );
}

export default function Header() {
  const showName = useBreakpointValue([false, true]);
  const timeStamp = useLatestSysStatusStore((s) => s.timeStamp);
  const { isOpen: isLoginModalOpen, onToggle: onLoginModalToggle } = useDisclosure();
  const { t } = useTranslation();
  const [isAuth, userName] = useUserStore((s) => [s.isAuth, s.userName], shallow);

  let location = useLocation();

  const currentHeader = useMemo(() => {
    if (location.pathname.includes(routeConfigs.dashboard) || location.pathname === '/') {
      return <Title>{t('header.dashboard.title')}</Title>;
    } else if (location.pathname.includes(routeConfigs.storage)) {
      return <Title>{t('header.storage.title')}</Title>;
    } else if (location.pathname.includes(routeConfigs.schedule)) {
      return <Title>{t('header.schedule.title')}</Title>;
    } else if (location.pathname.includes(routeConfigs.operate)) {
      return <Title>{t('header.operate.title')}</Title>;
    } else if (location.pathname.includes(routeConfigs.history)) {
      return <Title>{t('header.history.title')}</Title>;
    } else if (location.pathname.includes(routeConfigs.members)) {
      return <Title>{t('page.members.title')}</Title>;
    } else if (location.pathname.includes(routeConfigs.profile)) {
      return <Title>{t('page.profile.title')}</Title>;
    }
  }, [location.pathname, t]);

  return (
    <Flex direction={'column'} minH="140px" justify={'center'}>
      <Flex justify={'space-between'} alignItems="center">
        <Box>{currentHeader}</Box>
        <HStack>
          <Box color={colors.textPrimary} fontWeight={'normal'} fontSize="14px">
            {t('header.common.lastUpdate')}:<br />
            {timeStamp ? dayjs(timeStamp).format('YYYY-MM-DD HH:mm:ss') : t('error.noData')}
          </Box>
          {showName && <Sreg />}
          <Box display="flex" gap={4} alignItems="center">
            {isAuth ? (
              <MenuUser>
                <HStack cursor={'pointer'} spacing={4} onClick={() => {}}>
                  <Avatar size={['sm', 'md']} src="" />
                  {showName && (
                    <Text fontSize={'lg'} color={colors.textPrimary}>
                      {userName}
                    </Text>
                  )}
                </HStack>
              </MenuUser>
            ) : (
              <Button
                variant={'larger'}
                width={['75px', '150px']}
                height={['30px', '75px']}
                onClick={onLoginModalToggle}
                fontSize={['12px', '20px']}
              >
                {t('header.login.title')}
              </Button>
            )}
          </Box>
        </HStack>

        <LoginModal isOpen={isLoginModalOpen} onClose={onLoginModalToggle} />
      </Flex>
      {!showName && <Sreg mb={5} />}
    </Flex>
  );
}
