import { useCallback } from 'react';

import { muatateLogout } from 'apis/mutateLogout';
import { ILogoutRsps } from 'apis/types';
import useToast from 'hooks/useToast';
import { useTranslation } from 'react-i18next';
import { TbLogout2, TbSettingsFilled, TbUser } from 'react-icons/tb';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import useUserStore from 'stores/useUserStore';

import { Menu, MenuButton, MenuDivider, MenuItem, MenuList } from '@chakra-ui/react';

interface MenuUserProps {
  children: React.ReactNode;
}

export default function MenuUser({ children }: MenuUserProps) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const LogoutMutation = useMutation(muatateLogout);
  const isRoot = useUserStore((s) => s.isRoot);
  const setUserInfoDefault = useUserStore((s) => s.setUserInfoDefault);
  const [, setToast] = useToast();

  const toProfile = useCallback(() => {
    navigate('/profile');
  }, [navigate]);
  const toMembers = useCallback(() => {
    navigate('/members');
  }, [navigate]);

  const onLogoutSuccess = (data: ILogoutRsps) => {
    if (data.errors !== undefined) {
      onLogoutError(data.errors);
      return;
    }

    useUserStore.persist.clearStorage(); // clear local storage
    setUserInfoDefault(); // set user info to default
    navigate('/dashboard');
  };
  const onLogoutError = (error: unknown) => {
    console.error('logout error:', error);
    setToast({
      title: t('userMenu.logout.errorMsg.failed'),
      status: 'warning',
    });
  };

  return (
    <Menu variant={'user'}>
      <MenuButton py={2} borderRadius="md">
        {children}
      </MenuButton>
      <MenuList>
        <MenuItem icon={<TbUser size={'18px'} />} onClick={toProfile}>
          {t('userMenu.profile')}
        </MenuItem>
        {isRoot ? (
          <MenuItem icon={<TbSettingsFilled size={'18px'} />} onClick={toMembers}>
            {t('userMenu.members')}
          </MenuItem>
        ) : null}

        <MenuDivider />
        <MenuItem
          icon={<TbLogout2 size={'18px'} />}
          onClick={() => {
            LogoutMutation.mutate(undefined, {
              onSuccess: onLogoutSuccess,
              onError: onLogoutError,
            });
          }}
        >
          {t('userMenu.logout.title')}
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
