import { IUser } from 'apis/types';
import { IsAuthUser, IsRootUser } from 'utils/user';
import create from 'zustand';
import { persist } from 'zustand/middleware';

interface User {
  userId: string;
  userName: string;
  userEmail: string;
  userRole: string;
  isAuth: boolean; // user has logged in
  isRoot: boolean; // user role is root (root has all permissions)
  setUserInfo: (userInfo: IUser) => void;
  setUserInfoDefault: () => void;
}

export const useUserStore = create<User>()(
  persist(
    (set, get) => ({
      userId: '',
      userName: 'Guest',
      userEmail: '',
      userRole: 'guest',
      isAuth: false,
      isRoot: false,
      setUserInfo: (userInfo: IUser) =>
        set((state) => {
          return {
            userId: userInfo._id ?? state.userId,
            userName: userInfo.username ?? state.userName,
            userEmail: userInfo.email ?? state.userEmail,
            userRole: userInfo.role ?? state.userRole,
            isAuth: IsAuthUser(userInfo.role ?? state.userRole),
            isRoot: IsRootUser(userInfo.role ?? state.userRole),
          };
        }),
      setUserInfoDefault: () =>
        set(() => {
          return {
            userName: 'Guest',
            userEmail: '',
            userRole: 'guest',
            isAuth: false,
            isRoot: false,
          };
        }),
    }),
    {
      name: 'tenma-user-storage',
    }
  )
);

export default useUserStore;
