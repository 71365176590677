import { SvgIcon } from './type';

export default function TenmaLogo(props: SvgIcon) {
  return (
    <svg
      width="340"
      height="36"
      viewBox="0 0 340 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_346_17872)">
        <path
          d="M47.2899 35.93C37.9799 35.93 30.9399 29.37 30.9399 18C30.9499 6.58001 37.9799 0.0700073 47.2899 0.0700073C56.5399 0.0700073 63.6299 6.58001 63.6299 18C63.6299 29.42 56.5499 35.93 47.2899 35.93ZM47.2899 6.60001C41.9199 6.60001 38.4299 10.6 38.4299 18C38.4299 25.4 41.9199 29.4 47.2899 29.4C52.6399 29.4 56.1499 25.4 56.1499 18C56.1499 10.6 52.6399 6.60001 47.2899 6.60001Z"
          fill="#5EA0EB"
        />
        <path
          d="M81.45 12.44H81.7199L88.4999 1.03004C88.6799 0.730039 89.0099 0.540039 89.3599 0.540039H95.34C96.12 0.540039 96.5999 1.39004 96.1899 2.06004L86.4699 17.99L96.41 33.91C96.83 34.58 96.3499 35.44 95.5599 35.44H89.4399C89.0899 35.44 88.7599 35.26 88.5799 34.96L81.7099 23.53H81.4399L74.5799 34.97C74.3999 35.27 74.0699 35.45 73.7199 35.45H67.6399C66.8499 35.45 66.3799 34.59 66.7899 33.92L76.7599 18L66.9799 2.07004C66.5699 1.40004 67.0499 0.550039 67.8299 0.550039H73.84C74.19 0.550039 74.52 0.740039 74.7 1.04004L81.45 12.44Z"
          fill="#5EA0EB"
        />
        <path
          d="M120.74 0.550049H112.65C112.22 0.550049 111.84 0.820049 111.7 1.22005L100.35 34.1301C100.13 34.7801 100.61 35.46 101.3 35.46H107.08C107.51 35.46 107.9 35.18 108.03 34.7701L110.4 27.5H118.62C119.3 27.5 119.78 26.84 119.57 26.1901L118.36 22.43C118.23 22.0201 117.84 21.74 117.41 21.74H112.27L116.57 8.53005H116.84L125.36 34.7701C125.49 35.18 125.88 35.46 126.31 35.46H132.09C132.78 35.46 133.26 34.7801 133.04 34.1301L121.69 1.22005C121.55 0.820049 121.17 0.550049 120.74 0.550049Z"
          fill="#5EA0EB"
        />
        <path
          d="M206.41 35.4501H201.56C201.23 35.4501 200.92 35.2901 200.74 35.0201L185.85 13.48H185.59V34.4501C185.59 35 185.14 35.4501 184.59 35.4501H179.21C178.66 35.4501 178.21 35 178.21 34.4501V1.55005C178.21 1.00005 178.66 0.550049 179.21 0.550049H184.16C184.49 0.550049 184.8 0.710049 184.98 0.980049L199.75 22.5H200.06V1.55005C200.06 1.00005 200.51 0.550049 201.06 0.550049H206.41C206.96 0.550049 207.41 1.00005 207.41 1.55005V34.46C207.41 35.01 206.96 35.4501 206.41 35.4501Z"
          fill="#5EA0EB"
        />
        <path
          d="M290.23 6.60001C284.9 6.60001 281.26 10.71 281.26 17.97C281.26 25.26 284.75 29.41 290.29 29.41C295.2 29.41 298.15 26.67 298.25 22.34H291.75C291.2 22.34 290.75 21.89 290.75 21.34V17.77C290.75 17.22 291.2 16.77 291.75 16.77H304.36C304.91 16.77 305.36 17.22 305.36 17.77V21.17C305.36 30.36 299.07 35.93 290.26 35.93C280.43 35.93 273.78 29.08 273.78 18.03C273.78 6.72001 280.89 0.0700073 290.11 0.0700073C297.52 0.0700073 303.34 4.36001 304.81 10.6C304.96 11.23 304.48 11.83 303.83 11.83H298.26C297.83 11.83 297.47 11.55 297.32 11.15C296.23 8.29001 293.76 6.60001 290.23 6.60001Z"
          fill="#5EA0EB"
        />
        <path
          d="M315.11 0.550049C315.48 0.550049 315.82 0.750049 315.99 1.08005L323.67 15.58H324.01L331.69 1.08005C331.86 0.750049 332.2 0.550049 332.57 0.550049H338.52C339.29 0.550049 339.77 1.37005 339.39 2.04005L327.5 23.12V34.46C327.5 35.01 327.05 35.46 326.5 35.46H321.17C320.62 35.46 320.17 35.01 320.17 34.46V23.1101L308.29 2.04005C307.91 1.37005 308.4 0.550049 309.16 0.550049H315.11Z"
          fill="#5EA0EB"
        />
        <path
          d="M14.24 0.550049H1.46997C0.919971 0.550049 0.469971 1.00005 0.469971 1.55005V34.46C0.469971 35.01 0.919971 35.46 1.46997 35.46H6.84997C7.39997 35.46 7.84997 35.01 7.84997 34.46V6.58005H12.83C17.09 6.58005 19.15 8.90005 19.15 12.37C19.15 15.83 17.09 18.22 12.86 18.22H11.85C11.3 18.22 10.85 18.67 10.85 19.22V23.13C10.85 23.68 11.3 24.13 11.85 24.13H14.05C22.08 24.13 26.75 19.34 26.75 12.37C26.75 5.44005 22.17 0.550049 14.24 0.550049Z"
          fill="#5EA0EB"
        />
        <path
          d="M171.12 20.03V15.95C171.12 15.4 170.67 14.95 170.12 14.95H160.19C159.64 14.95 159.19 15.4 159.19 15.95V20.03C159.19 20.58 159.64 21.03 160.19 21.03H170.12C170.67 21.03 171.12 20.59 171.12 20.03Z"
          fill="#5EA0EB"
        />
        <path
          d="M156.19 29.37V6.63005H171.33C171.88 6.63005 172.33 6.18005 172.33 5.63005V1.55005C172.33 1.00005 171.88 0.550049 171.33 0.550049H149.81C149.26 0.550049 148.81 1.00005 148.81 1.55005V34.46C148.81 35.01 149.26 35.46 149.81 35.46H171.4C171.95 35.46 172.4 35.01 172.4 34.46V30.37C172.4 29.82 171.95 29.37 171.4 29.37H156.19Z"
          fill="#5EA0EB"
        />
        <path
          d="M235.81 20.03V15.95C235.81 15.4 235.36 14.95 234.81 14.95H224.88C224.33 14.95 223.88 15.4 223.88 15.95V20.03C223.88 20.58 224.33 21.03 224.88 21.03H234.81C235.36 21.03 235.81 20.59 235.81 20.03Z"
          fill="#5EA0EB"
        />
        <path
          d="M220.88 29.37V27.25V21.04V14.96V13.16V6.63005H236.02C236.57 6.63005 237.02 6.18005 237.02 5.63005V1.55005C237.02 1.00005 236.57 0.550049 236.02 0.550049H214.5C213.95 0.550049 213.5 1.00005 213.5 1.55005V34.46C213.5 35.01 213.95 35.46 214.5 35.46H236.09C236.64 35.46 237.09 35.01 237.09 34.46V30.37C237.09 29.82 236.64 29.37 236.09 29.37H220.88Z"
          fill="#5EA0EB"
        />
        <path
          d="M263.01 21.89C266.98 20.19 269.18 16.73 269.18 11.95C269.18 5.01005 264.6 0.550049 256.67 0.550049H243.9C243.35 0.550049 242.9 1.00005 242.9 1.55005V34.46C242.9 35.01 243.35 35.46 243.9 35.46H249.28C249.83 35.46 250.28 35.01 250.28 34.46V6.58005H255.26C259.52 6.58005 261.58 8.47005 261.58 11.95C261.58 15.3 259.63 17.02 255.67 17.13H254.16C253.4 17.13 252.92 17.9401 253.28 18.6L262.01 34.93C262.18 35.2601 262.52 35.46 262.89 35.46H268.75C269.51 35.46 269.99 34.65 269.63 33.98L263.01 21.89Z"
          fill="#5EA0EB"
        />
      </g>
      <defs>
        <clipPath id="clip0_346_17872">
          <rect width="339.06" height="35.86" fill="white" transform="translate(0.469971 0.0700073)" />
        </clipPath>
      </defs>
    </svg>
  );
}
