import { IBms, IController, IMeter, IPcs } from 'apis/types';
import create from 'zustand';

interface LatestSysStatus {
  bms: IBms;
  meter: IMeter;
  pcs: IPcs;
  controller: IController;
  timeStamp: number | undefined;
}

const useLatestSysStatusStore = create<LatestSysStatus>((set) => ({
  bms: {},
  meter: {},
  pcs: {},
  controller: {},
  timeStamp: undefined,
}));

export default useLatestSysStatusStore;
