import { SvgIcon } from './type';

export default function HistoryIcon(props: SvgIcon) {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Group">
        <path
          id="Vector"
          d="M30.7527 35H4.24727C1.91128 35 0 33.0495 0 30.6352V5.86522C0 5.63334 0.0929119 5.41509 0.252184 5.25141L5.10994 0.259161C5.26921 0.0954826 5.48159 0 5.70723 0H30.7527C33.0887 0 35 1.95052 35 4.36478V30.6489C35 33.0495 33.0887 35 30.7527 35ZM1.69886 6.21979V30.6352C1.69886 32.081 2.84037 33.2541 4.24727 33.2541H30.7527C32.1596 33.2541 33.3011 32.081 33.3011 30.6352V4.35113C33.3011 2.9053 32.1596 1.73229 30.7527 1.73229H6.06562L1.69886 6.21979Z"
          fill="white"
        />
        <path
          id="Vector_2"
          d="M29.2397 7.16098H10.074C9.60945 7.16098 9.22461 6.76548 9.22461 6.28809C9.22461 5.81069 9.60945 5.41512 10.074 5.41512H29.2397C29.7043 5.41512 30.0892 5.81069 30.0892 6.28809C30.0892 6.76548 29.7043 7.16098 29.2397 7.16098Z"
          fill="white"
        />
        <path
          id="Vector_3"
          d="M29.2399 29.9942H5.76052C5.29598 29.9942 4.91113 29.5986 4.91113 29.1212C4.91113 28.6438 5.29598 28.2482 5.76052 28.2482H29.2399C29.7044 28.2482 30.0893 28.6438 30.0893 29.1212C30.0893 29.5986 29.7044 29.9942 29.2399 29.9942Z"
          fill="white"
        />
        <path
          id="Vector_4"
          d="M11.0431 14.7721H5.76052C5.29598 14.7721 4.91113 14.3765 4.91113 13.8991C4.91113 13.4217 5.29598 13.0262 5.76052 13.0262H11.0431C11.5076 13.0262 11.8926 13.4217 11.8926 13.8991C11.8926 14.3765 11.5076 14.7721 11.0431 14.7721Z"
          fill="white"
        />
        <path
          id="Vector_5"
          d="M29.1066 14.7721H23.8241C23.3595 14.7721 22.9746 14.3765 22.9746 13.8991C22.9746 13.4217 23.3595 13.0262 23.8241 13.0262H29.1066C29.5711 13.0262 29.956 13.4217 29.956 13.8991C29.956 14.3765 29.5711 14.7721 29.1066 14.7721Z"
          fill="white"
        />
        <path
          id="Vector_6"
          d="M6.98166 22.3832H5.76052C5.29598 22.3832 4.91113 21.9876 4.91113 21.5102C4.91113 21.0328 5.29598 20.6372 5.76052 20.6372H6.98166C7.44621 20.6372 7.83113 21.0328 7.83113 21.5102C7.83113 21.9876 7.44621 22.3832 6.98166 22.3832Z"
          fill="white"
        />
        <path
          id="Vector_7"
          d="M29.1067 22.3832H27.8856C27.4211 22.3832 27.0361 21.9876 27.0361 21.5102C27.0361 21.0328 27.4211 20.6372 27.8856 20.6372H29.1067C29.5712 20.6372 29.9561 21.0328 29.9561 21.5102C29.9561 21.9876 29.5712 22.3832 29.1067 22.3832Z"
          fill="white"
        />
        <path
          id="Vector_8"
          d="M26.2132 25.8067H8.79944C8.49417 25.8067 8.21546 25.643 8.06946 25.3702C7.92346 25.0974 7.92346 24.77 8.06946 24.4972L16.7763 9.00234C17.0816 8.45674 17.9443 8.45674 18.2496 9.00234L26.9564 24.4972C27.1024 24.77 27.1024 25.0974 26.9564 25.3702C26.7839 25.6294 26.5052 25.8067 26.2132 25.8067ZM10.2594 24.0607H24.7399L17.5063 11.1847L10.2594 24.0607Z"
          fill="white"
        />
        <path
          id="Vector_9"
          d="M17.493 20.6918C17.0285 20.6918 16.6436 20.2962 16.6436 19.8188V15.4131C16.6436 14.9358 17.0285 14.5402 17.493 14.5402C17.9576 14.5402 18.3425 14.9358 18.3425 15.4131V19.8188C18.3425 20.3098 17.9708 20.6918 17.493 20.6918Z"
          fill="white"
        />
        <path
          id="Vector_10"
          d="M17.4937 23.106C17.0292 23.106 16.6045 22.6696 16.6045 22.1922C16.6045 21.7148 17.0292 21.2783 17.4937 21.2783C17.9583 21.2783 18.383 21.7148 18.383 22.1922C18.3963 22.6559 17.9716 23.106 17.4937 23.106Z"
          fill="white"
        />
        <path
          id="Vector_11"
          d="M3.62341 7.47472H0.84947C0.384927 7.47472 0 7.07914 0 6.60174C0 6.12435 0.384927 5.72877 0.84947 5.72877H3.62341C4.75159 5.72877 5.66745 4.78765 5.66745 3.62826V0.872973C5.66745 0.395577 6.0523 0 6.51684 0C6.98138 0 7.36631 0.395577 7.36631 0.872973V3.62826C7.36631 5.75608 5.69394 7.47472 3.62341 7.47472Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
