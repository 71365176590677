import useSregCount from 'hooks/useSregCount';
import { colors } from 'palette';
import { useTranslation } from 'react-i18next';

import { Box, BoxProps } from '@chakra-ui/react';

export function Sreg({ ...props }: BoxProps) {
  const { t } = useTranslation();
  const { sregCount, isSregMode } = useSregCount();

  if (isSregMode) {
    return (
      <Box
        bg={sregCount ? colors.dangerStatus : colors.normalStatus}
        color={colors.textWhite}
        borderRadius="12px"
        px={4}
        textAlign="center"
        lineHeight="48px"
        fontSize="20px"
        fontWeight="700"
        {...props}
      >
        {sregCount ? t('header.common.sregGTZero') : t('header.common.sregEQ')}
      </Box>
    );
  } else {
    return null;
  }
}
