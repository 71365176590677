export function Number2Complement(input: number | undefined, bits = 16) {
  if (input === undefined) return undefined;

  const maxUnsignedNumber = Math.pow(2, bits);
  if (input < 0) {
    return maxUnsignedNumber + input;
  }

  return input;
}

export function Complment2Number(input: number | undefined, bits = 16) {
  if (input === undefined) return undefined;
  const maxUnsignedNumber = Math.pow(2, bits);
  const maxPositive = Math.pow(2, bits - 1) - 1;
  if (input > maxPositive) {
    return -(maxUnsignedNumber - input);
  }

  return input;
}
