import i18n from 'i18n';
import { colors } from 'palette';
import { IoLanguage } from 'react-icons/io5';

import {
  Center,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useBreakpointValue,
} from '@chakra-ui/react';

export default function LanguageMenu() {
  const iconSize = useBreakpointValue([17, 35]);

  function onLanguageChange(language: string) {
    return function () {
      localStorage.setItem('language', language);
      i18n.changeLanguage(language);
    };
  }

  return (
    <Menu autoSelect={false}>
      <MenuButton
        as={IconButton}
        w={['30px', '60px']}
        h={['30px', '60px']}
        aria-label="Language"
        border={`1px solid ${colors.borderPrimary}`}
        borderRadius="10px"
        bg="transparent"
        px={2}
      >
        <Center>
          <IoLanguage
            style={{
              width: iconSize,
              height: iconSize,
              color: colors.navHeaderIcon,
            }}
          />
        </Center>
      </MenuButton>
      <MenuList>
        <MenuItem onClick={onLanguageChange('tw')}>繁體中文</MenuItem>
        <MenuItem onClick={onLanguageChange('en-US')}>English</MenuItem>
      </MenuList>
    </Menu>
  );
}
