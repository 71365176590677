import React from 'react';

import { colors } from 'palette';

/* eslint-disable react/jsx-pascal-case */
import { Heading as _Heading, HeadingProps, Text } from '@chakra-ui/react';

type IHeading = HeadingProps & {
  title: string;
  isSub?: boolean;
};

export default function Heading(props: IHeading) {
  const { title, isSub = false, ...restProps } = props;

  return (
    <_Heading
      fontWeight="medium"
      size={props?.size ? props?.size : isSub ? 'md' : 'lg'}
      mb={props?.mb || 2}
      color={colors.textSecondary}
      border={isSub ? '1px' : 'none'}
      borderColor={isSub ? colors.textSecondary : 'none'}
      borderRadius={isSub ? '10px' : 'none'}
      w={isSub ? 'fit-content' : 'auto'}
      p={isSub ? 2 : 0}
      {...restProps}
    >
      {title}
    </_Heading>
  );
}

export function SubTitle({ title }: { title: string | React.ReactNode }) {
  return (
    <Text py={'20px'} color={colors.textPrimary} fontSize={'3xl'} fontWeight="bold">
      {title}
    </Text>
  );
}
