import { Env } from 'configs/env';
import i18n from 'i18next';
import { Complment2Number, Number2Complement } from 'utils/Number/parser';
import { number } from 'yup';

export enum ScheduleMode {
  StandBy = 0,
  Manually = 1,
  Sreg = 2,
  Smart = 3,
  Dreg = 4,
}

export enum WeekString {
  Mon = 'monday',
  Tue = 'tuesday',
  Wed = 'wednesday',
  Thu = 'thursday',
  Fri = 'friday',
  Sat = 'saturday',
  Sun = 'sunday',
}

export interface IWeekSchedule {
  [date: string]: {
    dayOfWeek: WeekString;
    data: IScheduleData[];
  };
}
export interface IScheduleData {
  time: string;
  strategy: StrategyType;
}

export interface IStrategyBase {
  mode: ScheduleMode;
  name: string;
}

export interface IStandby extends IStrategyBase {}

export interface IManually extends IStrategyBase {
  chargingTarget: string;
  errorMsg?: string;
}

export interface ISreg extends IStrategyBase {
  contractCapacity: string;
  errorMsg?: string;
}

export interface ISmart extends IStrategyBase {}

export interface IDreg extends IStrategyBase {
  contractCapacity: string;
  errorMsg?: string;
}

export type StrategyType = IStandby | IManually | ISreg | ISmart | IDreg | undefined;

export function isScheduleMode(code: any): code is ScheduleMode {
  return code in ScheduleMode;
}

export function isStandby(strategy: StrategyType): strategy is IStandby {
  if (strategy?.mode === ScheduleMode.StandBy) {
    return true;
  }
  return false;
}
export function isManually(strategy: StrategyType): strategy is IManually {
  if (strategy?.mode === ScheduleMode.Manually) {
    return true;
  }
  return false;
}
export function isSreg(strategy: StrategyType): strategy is ISreg {
  if (strategy?.mode === ScheduleMode.Sreg) {
    return true;
  }
  return false;
}
export function isSmart(strategy: StrategyType): strategy is ISmart {
  if (strategy?.mode === ScheduleMode.Smart) {
    return true;
  }
  return false;
}
export function isDreg(strategy: StrategyType): strategy is IDreg {
  if (strategy?.mode === ScheduleMode.Dreg) {
    return true;
  }
  return false;
}

export function isAllSregOrSmart(
  tableData: IScheduleData[],
  option: 'both' | 'sreg' | 'smart' = 'both'
): boolean {
  let result = false;
  for (let idx = 0; idx < tableData.length; idx++) {
    if (
      (option !== 'smart' && isSreg(tableData[idx].strategy)) ||
      (option !== 'sreg' && isSmart(tableData[idx].strategy))
    ) {
      result = true;
      break;
    }
  }

  return result;
}

export const strategyDefaultValueMapping: [IStandby, IManually, ISreg, ISmart, IDreg] = [
  {
    mode: ScheduleMode.StandBy,
    name: i18n.t('page.schedule.strategies.standBy'),
  },
  {
    mode: ScheduleMode.Manually,
    name: i18n.t('page.schedule.strategies.manually'),
    chargingTarget: '',
  },
  {
    mode: ScheduleMode.Sreg,
    name: i18n.t('page.schedule.strategies.sReg'),
    contractCapacity: '',
  },
  {
    mode: ScheduleMode.Smart,
    name: i18n.t('page.schedule.strategies.smart'),
  },
  {
    mode: ScheduleMode.Dreg,
    name: i18n.t('page.schedule.strategies.dReg'),
    contractCapacity: '',
  },
];
export function ScheduleDataParser(data: number): StrategyType {
  const type = data >> 16;
  let inputStrategy = { ...strategyDefaultValueMapping[type] };
  if (isManually(inputStrategy)) {
    inputStrategy.chargingTarget = Complment2Number(data & 65535)!.toString();
  } else if (isSreg(inputStrategy) || isDreg(inputStrategy)) {
    inputStrategy.contractCapacity = (data & 65535).toString();
  }

  return inputStrategy;
}

export function ScheduleSerializer(strategyData: StrategyType) {
  if (!strategyData) return 0;
  const type = strategyData.mode << 16;
  let extraValue = 0;
  if (isManually(strategyData)) {
    extraValue = Number2Complement(Number(strategyData.chargingTarget))!;
  } else if (isSreg(strategyData) || isDreg(strategyData)) {
    extraValue = Number(strategyData.contractCapacity);
  }

  return type + extraValue;
}

export const manuallyInputValidation = number()
  .integer(i18n.t('page.schedule.errorMsg.integer')!)
  .required(i18n.t('page.schedule.errorMsg.isRequired')!)
  .min(
    Env.MANUAL_MIN_CAPACITY,
    i18n.t('page.schedule.errorMsg.minHint')! + Env.MANUAL_MIN_CAPACITY.toString()
  )
  .max(
    Env.MANUAL_MAX_CAPACITY,
    i18n.t('page.schedule.errorMsg.maxHint')! + Env.MANUAL_MAX_CAPACITY.toString()
  );

export const sregInputValueValidation = number()
  .integer(i18n.t('page.schedule.errorMsg.integer')!)
  .required(i18n.t('page.schedule.errorMsg.isRequired')!)
  .min(0, i18n.t('page.schedule.errorMsg.min')!)
  .max(Env.SREG_MAX_CAPACITY, i18n.t('page.schedule.errorMsg.maxHint')! + Env.SREG_MAX_CAPACITY.toString());

export const dregInputValueValidation = number()
  .integer(i18n.t('page.schedule.errorMsg.integer')!)
  .required(i18n.t('page.schedule.errorMsg.isRequired')!)
  .min(0, i18n.t('page.schedule.errorMsg.min')!)
  .max(Env.DREG_MAX_CAPACITY, i18n.t('page.schedule.errorMsg.maxHint')! + Env.DREG_MAX_CAPACITY.toString());
