export enum ElasticBase {
  Ten = 10,
  Hundred = 100,
}

export function Amplifier(input: number | undefined, base = ElasticBase.Ten) {
  if (input === undefined) {
    return undefined;
  }

  return input * base;
}

export function Compressor(input: number | undefined, base = ElasticBase.Ten) {
  if (input === undefined) {
    return undefined;
  }

  return input / base;
}
