import { colors } from 'palette';

import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  container: {},
  thumb: {
    bg: colors.bgContent,
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  },
  track: {
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset',
    bg: '#7C7E80',
    _checked: {
      bg: colors.appBlue,
    },
  },
});

export const switchTheme = defineMultiStyleConfig({ baseStyle });
