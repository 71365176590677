// import './index.css';
import './i18n/index';
import 'react-day-picker/dist/style.css';
import './css/DayPicker.css';

import React from 'react';

import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import { routeConfigs } from 'routes';

import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';

import ErrorPage from './pages/Error';
import Root from './pages/Root';
import theme from './theme';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />} errorElement={<ErrorPage />}>
      <Route index lazy={() => import('pages/Dashboard')} />
      <Route path={routeConfigs.dashboard} lazy={() => import('pages/Dashboard')} />
      <Route path={routeConfigs.schedule} lazy={() => import('pages/Schedule')} />
      <Route path={routeConfigs.operate} lazy={() => import('pages/Operate')} />
      <Route path={routeConfigs.present} lazy={() => import('pages/Present')} />
      <Route path={routeConfigs.history} lazy={() => import('pages/History')} />
      <Route path={routeConfigs.storage} lazy={() => import('pages/Storage')} />
      <Route path={routeConfigs.profile} lazy={() => import('pages/Profile')} />
      <Route path={routeConfigs.members} lazy={() => import('pages/Members')} />
    </Route>
  )
);

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <RouterProvider router={router} />
      </QueryClientProvider>
    </ChakraProvider>
  </React.StrictMode>
);
