import dayjs from 'dayjs';

import { IApiBase } from './types';

export async function fetchLatestSysStatus(duration: number): Promise<IApiBase> {
  const currentTime = dayjs();
  const startTime = currentTime.subtract(duration, 'second');
  const queryTime = `from:${startTime.unix()} to: ${currentTime.unix()}`;
  const meterQueryParamString = `meter(${queryTime} step:1){status soc r_phase_voltage s_phase_voltage t_phase_voltage r_phase_current s_phase_current t_phase_current hz total_watt power_factor timestamp }`;
  const bmsQueryParamString = `bms(${queryTime}){status timestamp system_heartbeat max_cell_voltage system_soh max_charge_current_limit mbmu_status min_cell_voltage max_cell_temp system_voltage avg_cell_temp min_cell_temp system_soc avg_cell_voltage system_current warning_without_degree max_discharge_current_limit warning_with_degree cell_temp cell_voltage bms_status sbmu01_warning_with_degree sbmu01_warning_without_degree sbmu02_warning_with_degree sbmu02_warning_without_degree sbmu03_warning_with_degree sbmu03_warning_without_degree sbmu04_warning_with_degree sbmu04_warning_without_degree sbmu01_soc sbmu02_soc sbmu03_soc sbmu04_soc}`;
  const pcsQueryParamString = `pcs(${queryTime} step:0){timestamp power_factor p fault nominal_power status warning heartbeat grid_active_power grid_reactive_power grid_rs_voltage grid_st_voltage grid_tr_voltage total_current_r total_current_s total_current_t dc_voltage_bus_1 dc_voltage_bus_2 dc_voltage_bus_3}`;
  const controllerQueryParamString = `controller(${queryTime} step:0){cbl status new_hz new_hz_timestamp mode warning max_soc min_soc hold_soc contract_capacity kw_t1 kw_t2 soc_t1 soc_t2 timestamp base_hz p manual_bid_kw manual_soc}`;
  const response = await fetch(
    (process.env.REACT_APP_API_URL ?? '') +
      '/graphql?' +
      new URLSearchParams(
        'query={' +
          meterQueryParamString +
          bmsQueryParamString +
          pcsQueryParamString +
          controllerQueryParamString +
          '}'
      )
  );

  return await response.json();
}
