import { colors } from 'palette';

import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const larger = defineStyle({
  bg: colors.largerButtonBg,
  borderRadius: '20px',
  width: '150px',
  height: '75px',
  fontSize: '20px',
  fontWeight: 'bold',
  color: colors.textPrimary,
});

const largerAct = defineStyle({
  bg: colors.largerButtonBg,
  borderRadius: '20px',
  width: '150px',
  height: '75px',
  fontSize: '20px',
  fontWeight: 'bold',
  color: colors.textPrimary,
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
});

const main = defineStyle({
  bg: colors.appBlue,
  borderRadius: '10px',
  height: '40px',
  minWidth: '120px',
  padding: '10px 30px',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  fontSize: '20px',
  color: colors.textLight,
  _disabled: {
    pointerEvents: 'none',
  },
});

const sub = defineStyle({
  bg: colors.subButtonBg,
  borderRadius: '10px',
  height: '40px',
  minWidth: '120px',
  padding: '10px 30px',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  fontSize: '20px',
  color: colors.textLight,
  _disabled: {
    pointerEvents: 'none',
  },
  /*
   _hover _disabled below is for bug patching,
   the loading button will vanished after click in mobile layout,
   reason unknown but it works
   */
  _hover: {
    bg: colors.subButtonBg,
    _disabled: {
      bg: colors.subButtonBg,
    },
  },
});

const subSub = defineStyle({
  bg: colors.appGray,
  borderRadius: '10px',
  height: '40px',
  minWidth: '120px',
  padding: '10px 30px',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  fontSize: '20px',
  color: colors.textSecondary,
  _disabled: {
    pointerEvents: 'none',
  },
});

const selector = defineStyle({
  color: colors.textSecondary,
  bg: colors.bgContent,
  width: '140px',
  padding: '10px',
  fontSize: '20px',
});

export const buttonTheme = defineStyleConfig({
  variants: { larger, largerAct, main, sub, selector, subSub },
});
