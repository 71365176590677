import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { fetchLatestSregCount } from 'apis/fetchLatestSregCount';
import { IApiBase } from 'apis/types';
import { ScheduleMode } from 'pages/Schedule/utils';
import { useQuery } from 'react-query';
import useAppConfigStore from 'stores/useAppConfigStore';

export default function useSregCount() {
  const [duration, interval] = useAppConfigStore((s) => [
    s.sregCountFetchDurationS,
    s.sregCountFetchIntervalMs,
  ]);
  const [sregCount, setSregCount] = useState<number | undefined>(undefined);
  const [isSregMode, setIsSregMode] = useState<boolean>(false);

  const [data, setData] = useState<IApiBase | undefined>();
  const [isError, setIsError] = useState<boolean>(false);

  const {
    isError: isFetchError,
    data: fetchData,
    error,
    refetch,
  } = useQuery(['latestSregCount', duration], () => fetchLatestSregCount(duration), {
    refetchInterval: interval,
  });
  useEffect(() => {
    setData(fetchData);
  }, [fetchData]);
  useEffect(() => {
    setIsError(isFetchError);
  }, [isFetchError]);

  const sregCountRefetch = useCallback(async () => {
    const { isError, data } = await refetch();
    setIsError(isError);
    setData(data);
  }, [refetch]);

  useEffect(() => {
    if (!isError && data && data.data && data.data.controller && data.data.controller.length > 0) {
      setSregCount(data.data.controller[data.data.controller.length - 1].sreg_count);
      setIsSregMode(data.data.controller[data.data.controller.length - 1].mode === ScheduleMode.Sreg);
    } else if (error) {
      console.warn('in useSregStatus error: ', error);
    } else {
      // console.log('fetching sreg_count, data:', data);
    }
  }, [data, error, isError]);

  return { sregCountRefetch, sregCount, isSregMode };
}
