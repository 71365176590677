import { SvgIcon } from './type';

export default function DashboardIcon(props: SvgIcon) {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Group">
        <path
          id="Vector"
          d="M17.5 35C7.85254 35 0 27.1475 0 17.5C0 7.85258 7.85254 0 17.5 0C27.1474 0 35 7.85258 35 17.5C35 27.1475 27.1599 35 17.5 35ZM17.5 1.24644C8.53808 1.24644 1.23396 8.53809 1.23396 17.5125C1.23396 26.4868 8.52562 33.7785 17.5 33.7785C26.4619 33.7785 33.766 26.4868 33.766 17.5125C33.766 8.53809 26.4743 1.24644 17.5 1.24644Z"
          fill="white"
        />
        <path
          id="Vector_2"
          d="M19.6191 24.1062C19.6066 24.1062 19.5817 24.1062 19.5692 24.1062C19.3324 24.0812 19.1205 23.9316 19.0457 23.7073L15.0945 12.963L13.3994 17.7992C13.3121 18.0485 13.0753 18.2105 12.8135 18.2105H7.42888C7.09235 18.2105 6.80566 17.9363 6.80566 17.5873C6.80566 17.2508 7.07988 16.9641 7.42888 16.9641H12.3773L14.4962 10.9188C14.5835 10.6696 14.8203 10.5075 15.0696 10.5075C15.3064 10.495 15.5682 10.6696 15.6554 10.9188L19.7312 22.0371L22.4361 17.2881C22.5482 17.0887 22.7477 16.9765 22.972 16.9765H27.5714C27.9079 16.9765 28.1946 17.2507 28.1946 17.5997C28.1946 17.9363 27.9204 18.223 27.5714 18.223H23.321L20.1301 23.8195C20.0429 23.994 19.8435 24.1062 19.6191 24.1062Z"
          fill="white"
        />
        <path
          id="Vector_3"
          d="M5.69585 19.9181C4.39955 19.9181 3.35254 18.8711 3.35254 17.5748C3.35254 16.2785 4.39955 15.2315 5.69585 15.2315C6.99215 15.2315 8.03909 16.2785 8.03909 17.5748C8.03909 18.8711 6.99215 19.9181 5.69585 19.9181ZM5.69585 16.4779C5.0851 16.4779 4.5865 16.9765 4.5865 17.5873C4.5865 18.1981 5.0851 18.6966 5.69585 18.6966C6.30661 18.6966 6.80512 18.1981 6.80512 17.5873C6.80512 16.9765 6.30661 16.4779 5.69585 16.4779Z"
          fill="white"
        />
        <path
          id="Vector_4"
          d="M29.316 19.9181C28.0197 19.9181 26.9727 18.8711 26.9727 17.5748C26.9727 16.2785 28.0197 15.2315 29.316 15.2315C30.6123 15.2315 31.6593 16.2785 31.6593 17.5748C31.6593 18.8711 30.5998 19.9181 29.316 19.9181ZM29.316 16.4779C28.7052 16.4779 28.2067 16.9765 28.2067 17.5873C28.2067 18.1981 28.7052 18.6966 29.316 18.6966C29.9267 18.6966 30.4253 18.1981 30.4253 17.5873C30.4129 16.9765 29.9267 16.4779 29.316 16.4779Z"
          fill="white"
        />
        <path
          id="Vector_5"
          d="M28.7301 14.5459C28.4684 14.5459 28.2315 14.3839 28.1443 14.1221C26.6361 9.49782 22.3608 6.39421 17.5122 6.39421C12.6511 6.39421 8.38822 9.49782 6.88003 14.1221C6.78031 14.4462 6.4313 14.6207 6.09476 14.521C5.77069 14.4213 5.59625 14.0723 5.69597 13.7357C7.3662 8.60039 12.1151 5.16025 17.5122 5.16025C22.9093 5.16025 27.6457 8.61285 29.3159 13.7357C29.4156 14.0598 29.2411 14.4088 28.9171 14.521C28.8547 14.5334 28.7925 14.5459 28.7301 14.5459Z"
          fill="white"
        />
        <path
          id="Vector_6"
          d="M17.4997 29.8522C12.1026 29.8522 7.3662 26.3996 5.69597 21.2767C5.59625 20.9526 5.77069 20.6036 6.09476 20.4914C6.41884 20.3793 6.76785 20.5662 6.88003 20.8903C8.38822 25.5146 12.6635 28.6182 17.5122 28.6182C22.3733 28.6182 26.6361 25.5146 28.1443 20.8903C28.244 20.5662 28.593 20.3917 28.9295 20.4914C29.2536 20.5912 29.4281 20.9402 29.3284 21.2767C27.6457 26.412 22.8968 29.8522 17.4997 29.8522Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
