import {
  useEffect,
  useMemo,
  useState,
} from 'react';

import useAppConfigStore from 'stores/useAppConfigStore';
import shallow from 'zustand/shallow';

export const FETCH_INTERVAL_MIN = 2; //second
export const FETCH_INTERVAL_MAX = 10; //second
const DEFAULT_FETCH_INTERVAL = 5; //second
const DEFAULT_FETCH_DURATION = 5; //second
const TENMA_FETCH_INTERVAL_KEY = 'tenma.fetch.interval';
const TENMA_FETCH_DURATION_KEY = 'tenma.fetch.duration';

export default function useAppConfig() {
  const [isInited, setIsInited] = useState(false);
  const [dataFetchIntervalS, dataFetchDurationS] = useAppConfigStore(
    (s) => [s.dataFetchIntervalS, s.dataFetchDurationS],
    shallow
  );

  useEffect(() => {
    const fetchIntervalS =
      Number(window.localStorage.getItem(TENMA_FETCH_INTERVAL_KEY)) || DEFAULT_FETCH_INTERVAL;
    const fetchIntervalMs = fetchIntervalS * 1000;

    const fetchDurationS =
      Number(window.localStorage.getItem(TENMA_FETCH_DURATION_KEY)) || DEFAULT_FETCH_DURATION;
    const fetchDurationMs = fetchDurationS * 1000;

    useAppConfigStore.setState({
      dataFetchIntervalS: fetchIntervalS,
      dataFetchIntervalMs: fetchIntervalMs,
      dataFetchDurationS: fetchDurationS,
      dataFetchDurationMs: fetchDurationMs,
    });

    setIsInited(true);
  }, []);

  useEffect(() => {
    if (dataFetchIntervalS >= FETCH_INTERVAL_MIN && dataFetchIntervalS <= FETCH_INTERVAL_MAX) {
      window.localStorage.setItem(TENMA_FETCH_INTERVAL_KEY, dataFetchIntervalS.toString());
    } else {
      window.localStorage.setItem(TENMA_FETCH_INTERVAL_KEY, DEFAULT_FETCH_INTERVAL.toString());
    }
    if (dataFetchDurationS <= dataFetchIntervalS) {
      window.localStorage.setItem(TENMA_FETCH_DURATION_KEY, dataFetchDurationS.toString());
    } else {
      window.localStorage.setItem(TENMA_FETCH_DURATION_KEY, DEFAULT_FETCH_DURATION.toString());
    }
  }, [dataFetchDurationS, dataFetchIntervalS]);

  return useMemo(() => ({ isInited }), [isInited]);
}
