export const colors = {
  // text
  textPrimary: 'var(--textPrimary)',
  textSecondary: 'var(--textSecondary)',

  textLight: 'var(--textLight)',
  textWhite: 'var(--textWhite)',
  // background
  bgPrimary: 'var(--bgPrimary)',
  bgContent: 'var(--bgContent)',
  bgGreen: 'var(--bgGreen)',
  // nav header
  navHeaderIcon: 'var(--navHeaderIcon)',
  // icon button
  borderPrimary: 'var(--borderPrimary)',
  // button
  largerButtonBg: 'var(--largerButtonBg)',
  scheduleIcon: 'var(--scheduleIcon)',
  subButtonBg: 'var(--subButtonBg)',
  // status
  normalStatus: 'var(--normalStatus)',
  nornalStatusDarker: 'var(--nornalStatusDarker)',
  warningStatus: 'var(--warningStatus)',
  dangerStatus: 'var(--dangerStatus)',
  // app
  appBlue: 'var(--appBlue)',
  appGray: 'var(--appGray)',
  // chart
  chartPurple: 'var(--chartPurple)',
  chartLightPurple: 'var(--chartLightPurple)',
  chartBlue: 'var(--chartBlue)',
  chartPeach: 'var(--chartPeach)',
  chartOrange: 'var(--chartOrange)',
  chartRed: 'var(--chartRed)',
  chartLightGreen: 'var(--chartLightGreen)',
  chartBlueGreen: 'var(--chartBlueGreen)',
  chartGreen: 'var(--chartGreen)',
};
